/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useContext, useEffect, useState } from 'react'
import { UsersIcon } from '@heroicons/react/24/outline'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { ContextServiceApi } from '../context/ContextServiceApi'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SearchPalette() {
    const [query, setQuery] = useState('')
    const [filteredProjects, setFilteredProjects] = useState([])
    const { openSearchPalette, setOpenSearchPalette, projects, setOpenProjectModal } = useContext(ContextServiceApi)


    useEffect(() => {
        setInterval(() => {

        }, 100)

    }, [projects])

    useEffect(() => {
        setFilteredProjects(
            query === ''
                ? []
                : projects.filter((project) => {
                    return project.name.toLowerCase().includes(query.toLowerCase())
                }))

    }, [query])

    return (
        <Transition.Root show={openSearchPalette} as={Fragment} afterLeave={() => setQuery('')} appear>
            <Dialog as="div" className="relative z-10" onClose={setOpenSearchPalette}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                            <Combobox onChange={(project) => { setOpenProjectModal(true, project); setOpenSearchPalette(false) }}>
                                <Combobox.Input
                                    className="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm"
                                    placeholder="Search..."
                                    onChange={(event) => setQuery(event.target.value)}
                                />

                                {filteredProjects.length > 0 && (
                                    <Combobox.Options
                                        static
                                        className="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                                    >
                                        {filteredProjects.map((project) => (
                                            <Combobox.Option
                                                key={project.id}
                                                value={project}
                                                className={({ active }) =>
                                                    classNames(
                                                        'cursor-default select-none rounded-md px-4 py-2',
                                                        active && 'bg-indigo-600 text-white'
                                                    )
                                                }
                                            >
                                                {project.name}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}

                                {query !== '' && filteredProjects.length === 0 && (
                                    <div className="px-4 py-14 text-center sm:px-14">
                                        <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                        <p className="mt-4 text-sm text-gray-900">No Projects found using that search term.</p>
                                    </div>
                                )}
                            </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

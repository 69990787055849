import { Transition } from "@headlessui/react"
import { TrashIcon } from "@heroicons/react/20/solid"
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline"
import _ from "lodash"
import { Fragment, useContext, useEffect, useState } from "react"
import { ContextServiceApi } from "../context/ContextServiceApi"

export default function GlobalFilterPanel(props) {
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [showRemoveDialog, setShowRemoveDialog] = useState(false)
    const [selectedItem, setSetSelectedItem] = useState()
    const [filterList, setFilterList] = useState([])
    const [newFilterItem, setNewFilterItem] = useState("")
    const {configFilters} = useContext(ContextServiceApi)

    function removeItem() {
        setFilterList(_.without(filterList, selectedItem))
        setSetSelectedItem(null)
    }

    function clearItems() {
        setFilterList([])
        setSetSelectedItem(null)
    }

    function addItem() {
        if (newFilterItem.length == 0) {
            alert("filter name cannot be empty")
        } else {
            setFilterList([...filterList, newFilterItem])
            setNewFilterItem("")
            setShowAddDialog(false)
        }
    }

    function handleFilterItemText(event) {
        setNewFilterItem(event.target.value)
    }

    useEffect(() => {
            setFilterList(configFilters)
    }, [configFilters])


    useEffect(() => {
        setTimeout(() => {

            if (selectedItem) {
                setShowRemoveDialog(true)
            } else {
                setShowRemoveDialog(false)
            }
        }, 100);
    }, [selectedItem])

    useEffect(() => {

        setTimeout(() => {
            if (typeof props.setProjectFilterList === 'function')
                props.setProjectFilterList(filterList)
        }, 200)

    }, [filterList])


    return <>
        <div className="grow relative divide-y divide-gray-200 overflow-hidden  bg-white shadow">

            <div className="flex  px-4 py-2 sm:px-6 place-items-center content-center bg-gray-300/50 ">
                <span className='grow font-bold text-xs uppercase'>Filtered By</span>
                {!props.readOnly && <>
                    <button type="button" onClick={clearItems} className="grid place-items-center  hover:text-red-700/80 hover:ring-red-700/80 text-black w-12 mx-3 p-1 ring-1 ring-inset ring-gray-400 rounded-md h-full text-xs uppercase">
                        <span className="text-[7pt]">Clear</span>
                    </button>
                    <button type="button" onClick={() => setShowAddDialog(true)} className='hover:text-indigo-800 hover:bg-sky-200/80 p-2 rounded-lg'>ADD</button>
                </>}
            </div>
            <div className={`flex flex-wrap gap-2 px-3 py-3 sm:p-3 ${!props.readOnly && 'justify-center'}`}>
                {
                    filterList &&
                    filterList?.map((item, itemtIdx) => (
                        <button key={itemtIdx} type="button" disabled={props.readOnly} onClick={() => setSetSelectedItem(item)}
                            className={`px-4 py-2 ${!props.readOnly && 'ring-1 hover:ring-indigo-400 hover:bg-indigo-800 hover:text-sky-200'} ${selectedItem === item && '!ring-indigo-600 !bg-indigo-200'} rounded-full  text-xs uppercase`}>{item}</button>
                    ))
                }
            </div>
            <Transition.Root show={showAddDialog} as={Fragment}>
                <div className={`absolute bottom-0 h-full w-full`}>
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="-translate-y-full"
                        enterTo="translate-y-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-y-0"
                        leaveTo="-translate-y-full"
                    >
                        <div className="absolute grid grid-cols-4 content-center min-h-14 w-full bg-blue-500/20 backdrop-blur-sm border-b">
                            <div className="grid  place-items-center py-2">
                                <button type="button" onClick={() => setShowAddDialog(false)} className="grid place-items-center bg-slate-100 hover:text-slate-700/80 hover:ring-slate-700/80 text-black w-12 mx-3 p-1 ring-1 ring-inset ring-gray-400 rounded-md h-full text-xs uppercase">
                                    <XMarkIcon className="w-4" />
                                    <span className="text-[7pt]">cancel</span>
                                </button>
                            </div>
                            <div className="col-span-2 grid place-items-center  text-center uppercase font-bold  mx-3 p-1">

                                <input value={newFilterItem} onChange={handleFilterItemText} placeholder="New Filter Item" className="w-full text-xs border-0.5 rounded-lg uppercase font-thin" />
                            </div>
                            <div className="grid  place-items-center py-2">
                                <button type="button" onClick={addItem} className="grid place-items-center  hover:text-red-700/80 hover:ring-red-700/80 text-black w-12 mx-3 p-1 ring-1 ring-inset ring-gray-400 rounded-md h-full text-xs uppercase">
                                    <PlusIcon className="w-4" />
                                    <span className="text-[7pt]">Add</span>
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Transition.Root>
            <Transition.Root show={showRemoveDialog} as={Fragment}>
                <div className={`absolute bottom-0 h-full w-full`}>
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="-translate-y-full"
                        enterTo="translate-y-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-y-0"
                        leaveTo="-translate-y-full"
                    >
                        <div className="absolute grid grid-cols-4 content-center min-h-14 w-full bg-indigo-500/20 backdrop-blur-sm border-b">
                            <div className="grid  place-items-center py-2">
                                <button type="button" onClick={() => setSetSelectedItem(null)} className="grid place-items-center bg-slate-100 hover:text-slate-700/80 hover:ring-slate-700/80 text-black w-12 mx-3 p-1 ring-1 ring-inset ring-gray-400 rounded-md h-full text-xs uppercase">
                                    <XMarkIcon className="w-4" />
                                    <span className="text-[7pt]">cancel</span>
                                </button>
                            </div>
                            <div className="col-span-2 grid place-items-center  text-center uppercase font-bold  mx-3 p-1">{selectedItem}</div>
                            <div className="grid  place-items-center py-2">
                                <button type="button" onClick={removeItem} className="grid place-items-center  hover:text-red-700/80 hover:ring-red-700/80 text-black w-12 mx-3 p-1 ring-1 ring-inset ring-gray-400 rounded-md h-full text-xs uppercase">
                                    <TrashIcon className="w-4" />
                                    <span className="text-[7pt]">Remove</span>
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Transition.Root>

        </div>
    </>
}
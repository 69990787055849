import { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import {
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    DocumentIcon,
    FolderArrowDownIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    FolderArrowDown,
    FolderOpenIcon,
    XMarkIcon,
    SignalIcon
} from '@heroicons/react/24/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import moment from 'moment/moment'
import { NewProject } from './NewProject'
import ProviderServiceApi from '../context/ProviderServiceApi'
import { ContextServiceApi } from '../context/ContextServiceApi'
import ProjectCardWindow from './ProjectCardWindow'
import RunState from './RunState'
import _ from 'lodash'
import { useAuthenticator } from '@aws-amplify/ui-react'
import ScrapeGridDisplayCard from './ScrapeGridDisplayCard'








function CardFooter(props) {

    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (props.project.isCompleted) {
            //setIsVisible(true)
        }
    }, [props.project])

    return <>
        {isVisible ?
            <div className='grid grid-cols-2 content-center h-14 bg-slate-0 m-2 gap-x-2'>
                <div className='grid place-items-center border-2 border-dashed border-zinc-100   p-2 uppercase text-xs gap-x-2'>
                    <button className='flex place-items-center bg-slate-0   p-2 uppercase text-xs gap-x-2'>
                        <FolderOpenIcon className='h-6 w-6 text-cyan-600' />
                        <dl className='flex flex-col'>
                            view results
                        </dl>
                    </button>
                </div>
                <div className='grid place-items-center border-2 border-dashed border-zinc-100   p-2 uppercase text-xs gap-x-2'>
                    <button className='flex place-items-center bg-slate-0   p-2 uppercase text-xs gap-x-2'>
                        <FolderArrowDownIcon className='h-6 w-6 text-cyan-600' />
                        <dl className='flex flex-col'>
                            download files
                        </dl>
                    </button>
                </div>
            </div>
            :
            <></>
        }
    </>
}

export function NewProjectCard() {
    return (
        <button
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
            <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                />
            </svg>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Create a new project</span>
        </button>
    )
}






export default function ScrapeGridDisplay() {

    const { setOpenProjectModal, projects, getProjects, projectStatusFilter, filteredProjects, setFilteredProjects, editProject } = useContext(ContextServiceApi)
    const { user, authStatus, signOut } = useAuthenticator((context) => [context]);
    const [randVal, setRandVal] = useState(0)

    useEffect(() => {
        getProjects().then((res) => {
            console.log(res)
        })
    }, [authStatus])

    useEffect(() => {
        setRandVal(null)
        new Promise((resolve, reject) => {
            setFilteredProjects([])
            setTimeout(() => {

                resolve()
            }, 1)
        }).then(() => {
            setRandVal(_.random(1, 100))
            setFilteredProjects(projects)
        })

    }, [projects])

    useEffect(() => {
        /* setInterval(() => {
            if (filteredProjects?.length > 0)
                editProject(filteredProjects[0].name, filteredProjects[0])
        }, 1000) */

    }, [])



    /* useEffect(() => {
        setRandVal(_.random(1, 100))
        setFilteredProjects(_.filter(projects, (project) => {
            switch (projectStatusFilter) {
                case "COMPLETED": return project.isCompleted; break;
                case "RUNNING": return project.isRunning; break;
                case "STOPPED": return project.isStopped; break;
                case "ERROR": return project.haveError; break;
                default: return project
            }
        }))
    }, [projectStatusFilter]) */

    const status = {
        pending: 'text-gray-800 bg-gradient-to-br from-slate-200 to-zinc-200 ring-1 ring-amber-600/20',
        running: 'text-gray-800 bg-gradient-to-br from-green-600 to-green-200 ring-1 ring-green-600/20',
        stopped: 'text-gray-800 bg-gradient-to-br from-red-500 to-red-200 ring-1 ring-slate-600/20',
        completed: 'text-gray-800 bg-gradient-to-br from-sky-600 to-slate-200 ring-1 ring-sky-600/20',
    }

    function className(project) {
        return `relative isolate min-w-[350px] max-w-[350px]  rounded-xl border border-2 shadow-lg bg-gradient-to-br group 
                ${project.haveError ? 'border-4 border-yellow-400' : 'border-sky-400/20'} 
                ${project.isRunning ? 'border-4 border-green-400' : 'border-sky-400/20'}
                ${project.isCompleted && 'animate-none animate-once animate-delay-0 animate-ease-in-out'}  
                ${project.isRunning ? status.running : (project.isCompleted ? status.completed : (project.isStopped ? status.stopped : status.pending))}
                            `
    }


    return (
        <>
            {/* {randVal > 0 &&
                <ul key={randVal} role="list" className="grid grid-cols-1 gap-x-4 gap-y-4 xs:grid-cols-1 md:grid-cols-2 lg:flex lg:flex-wrap xl:gap-x-4 justify-start max-w-7xl">
                    {
                        filteredProjects?.map &&
                        filteredProjects?.map((project, projecttIdx) => (
                            <li key={projecttIdx} className={`relative isolate min-w-[350px]`}>
                                <div className={className(project)}>
                                    <span className={` animate-bounce animate-infinite animate-delay-0 animate-ease-in-out bg-yellow-400 ring-0 ring-gray-800/40 rounded-full p-0 overflow-hidden absolute -mt-5 -ml-5 ${!project?.haveError && 'hidden'} `}>
                                        <ExclamationCircleIcon className="h-10 text-slate-700" aria-hidden="true" /></span>
                                    <span className={` animate-spin animate-infinite animate-delay-0 animate-ease-in-out bg-green-800 ring-0 ring-sky-800/40 rounded-full p-0 overflow-hidden absolute -mt-5 -ml-5 ${!project?.isRunning && 'hidden'} `}>
                                        <SignalIcon className="h-10 text-green-400" aria-hidden="true" /></span>
                                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 p-1">
                                        <img
                                            src="./Round TikTok.svg"
                                            alt={project.name}
                                            className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                                        />
                                        <div className="grid flex-grow text-sm font-bold leading-6  uppercase ">
                                            <div className="flex-grow text-sm font-bold leading-6  uppercase  w-full text-md  truncate hover:text-ellipsis  overflow-hidden">{project.name}</div>
                                            <div className="flex-grow text-xs font-thin leading-6  uppercase">TikTok</div>
                                        </div>
                                        <div className="inset-0 flex items-center justify-center">
                                            <button
                                                type="button"
                                                onClick={() => setOpenProjectModal(true, project)}
                                                className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium  group-hover:animate-pulse hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                            >
                                                Open
                                            </button>
                                        </div>
                                    </div>
                                    <dl className="-my-3 px-6 py-4 text-sm leading-6 divide-y divide-slate-400/50">
                                        <div className="flex justify-between gap-x-4 py-1 my-2 ring-1 ring-gray-200/0 rounded-full px-0 bg-white/0 bg-blur-sm">
                                            <dt className="font-bold uppercase">Created</dt>
                                            <dd className="text-gray-700">
                                                <time dateTime={project.dateCreated}>{moment(project.dateCreated).isValid && moment.tz(project.dateCreated, moment.tz.guess()).fromNow()}</time>
                                            </dd>
                                            <dd className="text-gray-700">
                                                <time dateTime={project.dateCreated}>{moment(project.dateCreated).isValid && moment.tz(project.dateCreated, moment.tz.guess()).format("MMM Do")}</time>
                                            </dd>
                                        </div>
                                        <div className="flex flex-col justify-between gap-x-4 py-3">
                                            <RunState project={project} shownInList />

                                        </div>
                                    </dl>
                                    <CardFooter project={project} />
                                </div>
                            </li>

                        ))}
                </ul>
            } */}
             {randVal > 0 &&
                <ul key={randVal} role="list" className="grid grid-cols-1 gap-x-4 gap-y-4 xs:grid-cols-1 md:grid-cols-2 lg:flex lg:flex-wrap xl:gap-x-4 justify-start max-w-7xl">
                    {
                        projects?.map &&
                        projects?.map((project, projecttIdx) => (
                            <ScrapeGridDisplayCard project={project}/>

                        ))}
                </ul>
            }
        </>
    )
}

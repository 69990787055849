/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import MainLayout from '../layouts/MainLayout'
import ScrapeGridDisplay from '../components/ScrapeGridsDiplay'

import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
import UserAuthModule from '../components/UserAuthModule'
Amplify.configure(awsExports);


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HomePage() {
  return (
    <MainLayout>
      <div>
        <ScrapeGridDisplay />
      </div>
    </MainLayout>
  )
}


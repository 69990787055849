import { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import {
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    DocumentIcon,
    FolderArrowDownIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    FolderArrowDown,
    FolderOpenIcon,
    XMarkIcon,
    SignalIcon
} from '@heroicons/react/24/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import moment from 'moment/moment'
import { NewProject } from './NewProject'
import ProviderServiceApi from '../context/ProviderServiceApi'
import { ContextServiceApi } from '../context/ContextServiceApi'
import ProjectCardWindow from './ProjectCardWindow'
import RunState from './RunState'
import _ from 'lodash'
import { useAuthenticator } from '@aws-amplify/ui-react'








function CardFooter(props) {

    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (props.project.isCompleted) {
            //setIsVisible(true)
        }
    }, [props.project])

    return <>
        {isVisible ?
            <div className='grid grid-cols-2 content-center h-14 bg-slate-0 m-2 gap-x-2'>
                <div className='grid place-items-center border-2 border-dashed border-zinc-100   p-2 uppercase text-xs gap-x-2'>
                    <button className='flex place-items-center bg-slate-0   p-2 uppercase text-xs gap-x-2'>
                        <FolderOpenIcon className='h-6 w-6 text-cyan-600' />
                        <dl className='flex flex-col'>
                            view results
                        </dl>
                    </button>
                </div>
                <div className='grid place-items-center border-2 border-dashed border-zinc-100   p-2 uppercase text-xs gap-x-2'>
                    <button className='flex place-items-center bg-slate-0   p-2 uppercase text-xs gap-x-2'>
                        <FolderArrowDownIcon className='h-6 w-6 text-cyan-600' />
                        <dl className='flex flex-col'>
                            download files
                        </dl>
                    </button>
                </div>
            </div>
            :
            <></>
        }
    </>
}

export default function ScrapeGridDisplayCard(props) {

    const { hub, setOpenProjectModal, projects, getProjects, projectStatusFilter, filteredProjects, editProject } = useContext(ContextServiceApi)
    const { user, authStatus, signOut } = useAuthenticator((context) => [context]);
    const [randVal, setRandVal] = useState(0)
    const [project, setProject] = useState(props.project)
    const [filteredProject, setFilteredProject] = useState(props.project)


    useEffect(() => {
        const testObj = {
            "name": "Caileneasley Old video Filter Test",
            "dateCreated": "2024-02-12 02:41 AM",
            "lastRunDate": null,
            "lastStopDate": null,
            "isRunning": false,
            "isCompleted": false,
            "isStopping": false,
            "isStopped": false,
            "canReRun": true,
            "haveError": false,
            "msg": null,
            "percentageCompleted": 0,
            "_STATEKEY": "Caileneasley Old video Filter Test"
        }
        setInterval(() => {
            
            hub?.on("projectChange", (response) => {
            //editProject(response.name, response)
            if (response.name == props.project.name) {
                console.log(response)
                setRandVal(_.random(1, 100))
                setFilteredProject(response)
            }
        });
        }, 1000)

        

    }, [])



    useEffect(() => {
        setRandVal(_.random(1, 100))
        setFilteredProject(() => {
            switch (projectStatusFilter) {
                case "COMPLETED": return project.isCompleted; break;
                case "RUNNING": return project.isRunning; break;
                case "STOPPED": return project.isStopped; break;
                case "ERROR": return project.haveError; break;
                default: return project
            }
        })
    }, [projectStatusFilter])

    const status = {
        pending: 'text-gray-800 bg-gradient-to-br from-slate-200 to-zinc-200 ring-1 ring-amber-600/20',
        running: 'text-gray-800 bg-gradient-to-br from-green-600 to-green-200 ring-1 ring-green-600/20',
        stopped: 'text-gray-800 bg-gradient-to-br from-red-500 to-red-200 ring-1 ring-slate-600/20',
        completed: 'text-gray-800 bg-gradient-to-br from-sky-600 to-slate-200 ring-1 ring-sky-600/20',
    }

    function className() {
        return `relative isolate min-w-[350px] max-w-[350px]  rounded-xl border border-2 shadow-lg bg-gradient-to-br group 
                ${filteredProject.haveError ? 'border-4 border-yellow-400' : 'border-sky-400/20'} 
                ${filteredProject.isRunning ? 'border-4 border-green-400' : 'border-sky-400/20'}
                ${filteredProject.isCompleted && 'animate-none animate-once animate-delay-0 animate-ease-in-out'}  
                ${filteredProject.isRunning ? status.running : (filteredProject.isCompleted ? status.completed : (filteredProject.isStopped ? status.stopped : status.pending))}                      `
    }


    return (
        <>
            {randVal > 0 &&
                <li key={randVal} className={`relative isolate min-w-[350px]`}>
                    <div className={className(filteredProject)}>
                        <span className={` animate-bounce animate-infinite animate-delay-0 animate-ease-in-out bg-yellow-400 ring-0 ring-gray-800/40 rounded-full p-0 overflow-hidden absolute -mt-5 -ml-5 ${!project?.haveError && 'hidden'} `}>
                            <ExclamationCircleIcon className="h-10 text-slate-700" aria-hidden="true" /></span>
                        <span className={` animate-spin animate-infinite animate-delay-0 animate-ease-in-out bg-green-800 ring-0 ring-sky-800/40 rounded-full p-0 overflow-hidden absolute -mt-5 -ml-5 ${!project?.isRunning && 'hidden'} `}>
                            <SignalIcon className="h-10 text-green-400" aria-hidden="true" /></span>
                        <div className="flex items-center gap-x-4 border-b border-gray-900/5 p-1">
                            <img
                                src="./Round TikTok.svg"
                                alt={filteredProject.name}
                                className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                            />
                            <div className="grid flex-grow text-sm font-bold leading-6  uppercase ">
                                <div className="flex-grow text-sm font-bold leading-6  uppercase  w-full text-md  truncate hover:text-ellipsis  overflow-hidden">{filteredProject.name}</div>
                                <div className="flex-grow text-xs font-thin leading-6  uppercase">TikTok</div>
                            </div>
                            <div className="inset-0 flex items-center justify-center">
                                <button
                                    type="button"
                                    onClick={() => setOpenProjectModal(true, filteredProject)}
                                    className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium  group-hover:animate-pulse hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                >
                                    Open
                                </button>
                            </div>
                        </div>
                        <dl className="-my-3 px-6 py-4 text-sm leading-6 divide-y divide-slate-400/50">
                            <div className="flex justify-between gap-x-4 py-1 my-2 ring-1 ring-gray-200/0 rounded-full px-0 bg-white/0 bg-blur-sm">
                                <dt className="font-bold uppercase">Created</dt>
                                <dd className="text-gray-700">
                                    <time dateTime={filteredProject.dateCreated}>{moment(filteredProject.dateCreated).isValid && moment.tz(filteredProject.dateCreated, moment.tz.guess()).fromNow()}</time>
                                </dd>
                                <dd className="text-gray-700">
                                    <time dateTime={filteredProject.dateCreated}>{moment(filteredProject.dateCreated).isValid && moment.tz(filteredProject.dateCreated, moment.tz.guess()).format("MMM Do")}</time>
                                </dd>
                            </div>
                            <div className="flex flex-col justify-between gap-x-4 py-3">
                                <RunState project={filteredProject} shownInList />

                            </div>
                        </dl>
                        <CardFooter project={filteredProject} />
                    </div>
                </li>
            }
        </>
    )
}

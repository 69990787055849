import { useEffect } from "react"

export default function ConfigPanel(props) {

    useEffect(() => {
        console.log(props.config)
    }, [props.config])

    return <>
        <div className="relative overflow-hidden  bg-white ">
            <div className="flex  px-4 py-2 sm:px-6 justify-center content-center bg-gray-300/50 ">
                <span className='grow font-bold text-xs uppercase'>Config</span>
            </div>
            <div className='grid p-4 my-4 gap-5 text-gray-500 text-sm'>
                <div className='relative flex h-fit flex-wrap w-full  overflow-hidden'>
                    <h2 className='text-xs font-bold'>API BASE URL</h2>
                    <div className=' top-5 flex flex-wrap w-full  overflow-hidden hover:overflow-auto hover:text-slate-700 py-3'>
                        <div className='break-all'>
                            {props.config?.apiBaseUrl}
                        </div>
                    </div>
                </div>
                <div className='relative flex h-fit flex-wrap w-full  overflow-hidden'>
                    <h2 className='text-xs font-bold'>API KEY</h2>
                    <div className=' top-5 flex flex-wrap w-full  overflow-hidden hover:overflow-auto hover:text-slate-700 py-3'>
                        <div className='break-all'>
                            {props.config?.apiKey}
                        </div>
                    </div>
                </div>
                <div className=' flex h-auto flex-wrap w-full '>
                    <h2 className='text-xs font-bold'>API ACCOUNT KEY</h2>
                    <div className=' flex flex-wrap w-full  overflow-hidden hover:overflow-auto hover:text-slate-700 py-3'>
                        <div className='break-all'>
                            {props.config?.apiAccountKey}
                        </div>
                    </div>
                </div>
                <div className=' flex h-auto flex-wrap w-full '>
                    <h2 className='text-xs font-bold'>COUNT</h2>
                    <div className=' flex flex-wrap w-full  overflow-hidden hover:overflow-auto hover:text-slate-700 py-3'>
                        <div className='break-all'>
                            {props.config?.count}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    FolderOpenIcon,
    InformationCircleIcon,
    TrashIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import RunState from './RunState'
import { Button } from '@aws-amplify/ui-react'
import { ClockIcon, ExclamationTriangleIcon, FolderArrowDownIcon } from '@heroicons/react/20/solid'
import moment, { isMoment } from 'moment-timezone'

import { ContextServiceApi } from '../context/ContextServiceApi'
import FilterPanel from './FilterPanel'
import ConfigPanel from './ConfigPanel'
import _ from 'lodash'
import DownloadProgressBar from './DownloadProgressBar'

function DeletePane(props) {
    const [deleteDialogState, setDeleteDialogState] = useState(false)
    const [style, setStyle] = useState("")
    const { permissions } = useContext(ContextServiceApi)

    useEffect(() => {
        if (deleteDialogState) {
            setStyle("rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-none ring-0 ring-inset ring-gray-300 hover:bg-gray-50 mr-8")
        } else {
            setStyle("rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-8")
        }
    }, [deleteDialogState])


    return (
        <div className='flex  gap-4'>
            <button
                type="button"
                disabled={deleteDialogState}
                className={`flex min-w-24 ${style}`}
                onClick={() => setDeleteDialogState(true)}
            >Delete Project</button>
            {deleteDialogState &&
                <div>{props.children}</div>
            }
            {deleteDialogState &&
                <button
                    type="button"
                    className={`${permissions.canDeleteProject ? 'block' : 'hidden'} 0  flex min-w-24  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
                    onClick={() => setDeleteDialogState(false)}
                >No</button>
            }
        </div>
    )
}


function StopButton(props) {
    const { projects, getProjects, deleteProject, downloadProject, tikTokRun, tikTokReRun, tikTokStop,
        selectedProject, openProjectModal, setOpenProjectModal, getProjectFilters, getProjectConfig, setShowStartPrompt, setShowReStartPrompt } = useContext(ContextServiceApi)
    const [submitStatus, setSubmitStatus] = useState(null)
    const activeStateStyle = 'text-red-600 ring-red-400 hover:shadow-xl hover:font-bold shadow-lg'
    const inActiveStateStyle = 'bg-slate-100 ring-slate-400 text-slate-600 opacity-10'
    const [disabled, setDisabled] = useState(false)
    const [project, setProject] = useState(selectedProject)

    const [style, setStyle] = useState(inActiveStateStyle)

    switch (props.renderType) {
        case 'card': break;
        case 'list': break;
        default: <></>;
    }


    useEffect(() => {
        setDisabled(false)
        setProject(selectedProject)

    }, [])

    useEffect(() => {
        setTimeout(() => {
            setDisabled(project?.isRunning ? false : project?.isStopping ? true : true)
            if (project?.isRunning || project?.isStopping) {
                setStyle(inActiveStateStyle)
            } else {
                setStyle(activeStateStyle)
            }
        }, 100)

    }, [project])

    useEffect(() => {
        setProject(_.find(projects, { name: selectedProject?.name }))
    }, [projects])

    function submitTikTokStop(event) {
        tikTokStop(selectedProject).then((res) => {
            console.log(res)
            setSubmitStatus(res)
        }).then(() => {
            getProjects()
        })
    }

    return <>
        <Button onClick={submitTikTokStop}
            disabled={disabled}
            className={`ring-1  p-2 w-24 rounded-lg  ${style}`}>
            STOP</Button>
    </>
}

function RunButton() {//START
    const { projects, getProjects, deleteProject, downloadProject, tikTokRun, tikTokReRun, tikTokStop, submitTikTokStop,
        selectedProject, openProjectModal, setOpenProjectModal, getProjectFilters, getProjectConfig, setShowStartPrompt, setShowReStartPrompt } = useContext(ContextServiceApi)
    const [submitStatus, setSubmitStatus] = useState(null)
    const activeStateStyle = 'text-sky-600 ring-sky-400 hover:shadow-xl hover:font-bold shadow-lg'
    const inActiveStateStyle = 'bg-slate-100 ring-slate-400 text-slate-600 opacity-50'

    const [disabled, setDisabled] = useState(false)
    const [project, setProject] = useState(selectedProject)

    const [style, setStyle] = useState(inActiveStateStyle)
    useEffect(() => {
        setDisabled(false)
        setProject(selectedProject)

    }, [])

    useEffect(() => {
        setTimeout(() => {
            setDisabled(project?.isRunning ? true : project?.isStopping ? true : false)
            if (project?.isRunning || project?.isStopping) {
                setStyle(inActiveStateStyle)
            } else {
                setStyle(activeStateStyle)
            }
        }, 100)

    }, [project])

    useEffect(() => {
        setProject(_.find(projects, { name: selectedProject?.name }))
    }, [projects])

    return <>
        <Button type='button' onClick={() => setShowStartPrompt(true)}
            disabled={disabled}
            className={`ring-1  p-2 w-24 rounded-lg    ${style}`}>
            START
        </Button>
    </>
}

function ReRunButton() {
    const { projects, getProjects, deleteProject, downloadProject, tikTokRun, tikTokReRun, tikTokStop, submitTikTokStop,
        selectedProject, openProjectModal, setOpenProjectModal, getProjectFilters, getProjectConfig, setShowStartPrompt, setShowReStartPrompt } = useContext(ContextServiceApi)
    const [submitStatus, setSubmitStatus] = useState(null)
    const activeStateStyle = 'text-sky-600 ring-sky-400 hover:shadow-xl hover:font-bold shadow-lg'
    const inActiveStateStyle = 'bg-slate-100 ring-slate-400 text-slate-600 opacity-50'

    const [disabled, setDisabled] = useState(false)
    const [project, setProject] = useState(selectedProject)

    const [style, setStyle] = useState(inActiveStateStyle)
    useEffect(() => {
        setDisabled(false)
        setProject(selectedProject)

    }, [])

    useEffect(() => {
        setTimeout(() => {
            setDisabled(project?.isRunning ? true : project?.isStopping ? true : false)
            if (project?.isRunning || project?.isStopping) {
                setStyle(inActiveStateStyle)
            } else {
                setStyle(activeStateStyle)
            }
        }, 100)

    }, [project])

    useEffect(() => {
        setProject(_.find(projects, { name: selectedProject?.name }))
    }, [projects])

    return <>
        <Button type='button' onClick={() => setShowReStartPrompt(true)}
            disabled={disabled}
            className={`ring-1  p-2 w-24 rounded-lg    ${style}`}>
            RESTART
        </Button>
    </>
}

export default function ProjectCardWindow(props) {


    const { projects, fileDownloadData, clearDownloadData, setFileData, getProjects, deleteProject, downloadProject, tikTokRun, tikTokReRun, tikTokStop,
        selectedProject, openProjectModal, setOpenProjectModal, getProjectFilters, getProjectConfig, setShowStartPrompt, setShowReStartPrompt } = useContext(ContextServiceApi)


        const [project, setProject] = useState(selectedProject)
    const [submitStatus, setSubmitStatus] = useState(null)
    const [filters, setFilters] = useState([])
    const [config, setConfig] = useState()

    function submitDeleteProject(event) {
        deleteProject(project).then((res) => {
            console.log(res)
            setSubmitStatus(res)
        }).then(() => {
            getProjects()
            setOpenProjectModal(false)
        })
    }
    function submitDownloadProject(event) {
        event.preventDefault()

        downloadProject(project).then((res) => {
            console.log(res)
            if (res.message == 'canceled') {
                setFileData(null)
                clearDownloadData()
            } else {
                setFileData(res)
            }
        }).then(() => {
        }).catch((err) => {
            console.log(err)
        })
    }
    function submitTikTokReRun(event) {
        tikTokReRun(project).then((res) => {
            console.log(res)
            setSubmitStatus(res)
        }).then(() => {
            getProjects()
            // setOpenProjectModal(false)
        })
    }

    function MessagePanel(props) {
        let errorStyle="border-l-4 border-yellow-400 bg-yellow-50 p-4 text-yellow-700"
        let infoStyle="border-l-4 border-blue-400 bg-blue-50 p-4 text-blue-700"

        return (
            <div className={`${project?.haveError ? errorStyle : infoStyle}`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        {
                          props.haveError ? 
                          <ExclamationTriangleIcon className="h-5 w-5" aria-hidden="true" />
                            : <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
                        }
                        </div>
                    <div className="ml-3">
                        <p className="text-sm ">
                            {props.message}
                        </p>
                    </div>
                </div>
            </div>)
    }






    useEffect(() => {
        if (submitStatus?.success == false)
            alert(submitStatus.msg)
    }, [submitStatus])

    useEffect(() => {
        if (selectedProject) {
            console.log(selectedProject)
            getProjectFilters(selectedProject).then((res) => {
                setFilters(res.filterTexts)
            })
            getProjectConfig(selectedProject).then((res) => {
                setConfig(res.settings)
            })

            setProject(selectedProject)
        }
    }, [selectedProject])

    useEffect(() => {
        setProject(_.find(projects, { name: project?.name }))
    }, [projects])

    return (
        <div>

            <Transition.Root show={openProjectModal} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={() => { }}>
                    <div className="fixed inset-0" />
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute  inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <form className="flex h-full pt-0 flex-col divide-y divide-gray-200 bg-white shadow-xl ">
                                            <div className="flex flex-col px-0 h-32 py-4 mb-10">
                                                <div className="flex items-center justify-between mb-5 px-5">
                                                    <Dialog.Title className="text-base uppercase font-semibold leading-6 text-white">
                                                        <div className="flex items-center gap-x-4  px-1">
                                                            <img
                                                                src="./Round TikTok.svg"
                                                                alt={project?.name}
                                                                className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                                                            />
                                                            <div className="flex-grow text-xl truncate font-thin leading-6 text-sky-500">{selectedProject?.name}</div>

                                                        </div>
                                                    </Dialog.Title>
                                                    <div className="mx-3 flex h-fit items-center">
                                                        <button
                                                            type="button"
                                                            className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => setOpenProjectModal(false)}
                                                        >

                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='flex flex-grow px-6'><RunState project={project} /></div>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between py-4 overflow-y-auto">
                                                <div className="divide-y divide-gray-200 px-4 sm:px-6">

                                                    <div className="p-0">
                                                        <div className="overflow-hidden bg-slate-0  ring-1 ring-zinc-100 ">
                                                            <ul role="list" className="divide-y divide-gray-100">
                                                                {project?.msg &&
                                                                    <li>
                                                                        <MessagePanel message={project?.msg} haveError={project?.haveError}/>
                                                                    </li>
                                                                }
                                                                {
                                                                    project?.isStopping ?
                                                                        < li><MessagePanel>Stopping</MessagePanel></li>
                                                                        :
                                                                        <li className="flex flex-row justify-items-center px-4 py-4 sm:px-6 h-32 gap-4">

                                                                            <StopButton />
                                                                            <ReRunButton />
                                                                            <RunButton />
                                                                        </li>
                                                                }
                                                                {
                                                                    fileDownloadData?.total > 0 &&
                                                                    <li className="px-4 py-4 sm:px-6 min-h-32">
                                                                        <div>
                                                                            <DownloadProgressBar />
                                                                            <span className='uppercase text-[7pt]'>{fileDownloadData?.statusMsg == 'completed' ? 'download completed' : fileDownloadData?.statusMsg}</span>
                                                                        </div>
                                                                    </li>
                                                                }
                                                                <li className="px-4 py-4 sm:px-6 min-h-32">
                                                                    <div className='grid grid-cols-2 content-center h-14 bg-slate-0 m-2 gap-x-2'>
                                                                        <div className='hidden grid place-items-center border-2 border-dashed border-zinc-100   p-2 uppercase text-xs gap-x-2'>
                                                                            <button disabled={fileDownloadData?.total > 0} className='flex place-items-center bg-slate-0   p-2 uppercase text-xs gap-x-2'>
                                                                                <FolderOpenIcon className='h-6 w-6 text-cyan-600' />
                                                                                <dl className='flex flex-col'>
                                                                                    view results
                                                                                </dl>
                                                                            </button>
                                                                        </div>
                                                                        <div className='grid place-items-center border-2 border-dashed border-zinc-100    p-2 uppercase text-xs gap-x-2'>
                                                                            <button disabled={selectedProject?.isRunning || (fileDownloadData?.loaded > 0 && fileDownloadData?.loaded < fileDownloadData?.total)}
                                                                                type='button' className={`${(selectedProject?.stopped || (fileDownloadData?.loaded > 0 && fileDownloadData?.loaded < fileDownloadData?.total)) && 'grayscale'} w-full h-full group focus:bg-sky-200  hover:opacity-50  ${!selectedProject?.IsRunning ? 'text-cyan-600' : 'text-gray-200'}`}
                                                                                onClick={submitDownloadProject}>
                                                                                <div className='grid grid-cols-5 w-full h-full'>
                                                                                    <div className='col-span-1 grid  place-items-center text-xs'>
                                                                                        <FolderArrowDownIcon className={`${(fileDownloadData?.loaded > 0 && fileDownloadData?.loaded < fileDownloadData?.total) && 'animate-bounce'} h-6 w-6 group-focus:animate-ping focus:animate-once`} />
                                                                                    </div>
                                                                                    <dl className='col-span-4 grid  place-items-center uppercase text-[8pt]'>
                                                                                        Start Download
                                                                                    </dl>
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="flex flex-col gap-4  py-4 sm:px-0 min-h-32">
                                                                    <div className="flex  px-4 py-2 sm:px-6 justify-center content-center bg-gray-300/50 ">
                                                                        <span className='grow font-bold text-xs uppercase'>In <span>{moment.tz.guess()}</span> Timezone</span>
                                                                    </div>
                                                                    <div className='grid px-4 gap-y-4'>
                                                                        <p className='flex gap-3'><ClockIcon className='w-4' />
                                                                            <span className='uppercase text-xs'>Created:</span>
                                                                            <span className='uppercase text-xs font-bold'>{moment(project?.dateCreated).isValid() && moment.tz(project?.dateCreated, moment.tz.guess()).format("Do MMM hh:mm A")}</span>
                                                                        </p>
                                                                        <p className='flex gap-3'><ClockIcon className='w-4' />
                                                                            <span className='uppercase text-xs'>Last Run:</span>
                                                                            <span className='uppercase text-xs font-bold'>{moment(project?.lastRunDate).isValid() && moment.tz(project?.lastRunDate, moment.tz.guess()).format("Do MMM hh:mm A")}</span>
                                                                        </p>
                                                                        <p className='flex gap-3'><ClockIcon className='w-4' />
                                                                            <span className='uppercase text-xs'>Last Stop:</span>
                                                                            <span className='uppercase text-xs font-bold'>{moment(project?.lastStopDate).isValid() && moment.tz(project?.lastStopDate, moment.tz.guess()).format("Do MMM hh:mm A")}</span>
                                                                        </p>
                                                                    </div>
                                                                </li>

                                                                <FilterPanel readOnly project={project} configFilters={filters} />

                                                                <ConfigPanel readOnly project={project} config={config} />

                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                <DeletePane>
                                                    <button
                                                        type="button"
                                                        className="flex min-w-24  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                        onClick={submitDeleteProject}
                                                    >
                                                        <TrashIcon className='w-5' />
                                                        Yes
                                                    </button>
                                                </DeletePane>

                                            </div>
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}
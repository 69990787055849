import { useContext, useEffect, useState } from "react"
import { ContextServiceApi } from "../context/ContextServiceApi"

export default function GlobalConfigPanel(props) {
    const { configSettings } = useContext(ContextServiceApi)

    const [newPlan_url, setNewPlan_url] = useState(configSettings?.apiBaseUrl)
    const [newPlan_apiKey, setNewPlan_apiKey] = useState(configSettings?.apiKey)
    const [newPlan_apiAccountKey, setNewPlan_apiAccountKey] = useState(configSettings?.apiAccountKey)

    function handleNewPlan_urlChange(event) {
        setNewPlan_url(event.target.value)
    }
    function handleNewPlan_apiKeyChange(event) {
        setNewPlan_apiKey(event.target.value)
    }
    function handleNewPlan_apiAccountKeyChange(event) {
        setNewPlan_apiAccountKey(event.target.value)
    }

    useEffect(() => {
        props.setProjectConfigList({
            "apiBaseUrl": newPlan_url,
            "apiKey": newPlan_apiKey,
            "apiAccountKey": newPlan_apiAccountKey,
            "count": 0
        })
    }, [newPlan_url, newPlan_apiKey, newPlan_apiAccountKey])


    useEffect(() => {
        setNewPlan_url(configSettings?.apiBaseUrl)
        setNewPlan_apiKey(configSettings?.apiKey)
        setNewPlan_apiAccountKey(configSettings?.apiAccountKey)
    }, [configSettings])

    return <>
        <div className="grow relative overflow-auto bg-white ">
            <div className="flex  px-4 py-2 sm:px-6 justify-center content-center bg-gray-300/50 ">
                <span className='grow font-bold text-xs uppercase'>Config</span>
            </div>
            <div className='flex flex-col space-y-2 bg-white p-2 my-3 rounded'>
                <label className='font-black text-gray-500 text-xs indent-2'>URL</label>
                <input id='newPlan_url' value={newPlan_url} onChange={handleNewPlan_urlChange} placeholder='ENTER BASE API URL' className='text-black rounded-lg  border-gray-300 hover:border-gray-400' />

                <label className='font-black text-gray-500 text-xs indent-2'>API KEY</label>
                <input id='newPlan_apiKey' value={newPlan_apiKey} onChange={handleNewPlan_apiKeyChange} placeholder='ENTER API KEY' className='text-black rounded-lg  border-gray-300 hover:border-gray-400' />

                <label className='font-black text-gray-500 text-xs indent-2'>API ACCOUNT KEY</label>
                <input id='newPlan_apiAccountKey' value={newPlan_apiAccountKey} onChange={handleNewPlan_apiAccountKeyChange} placeholder='ENTER API ACCOUNT KEY' className='text-black rounded-lg border-gray-300 hover:border-gray-400' />
            </div>
        </div>
    </>
}
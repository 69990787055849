import { useContext, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { ContextServiceApi } from '../context/ContextServiceApi'
import _ from 'lodash'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function RunState(props) {

    const [project, setProject] = useState(null)
    const [randKey, setRandKey] = useState(0)
    const [state, setState] = useState("pending")
    const [elapsedTime, setElapsedTime] = useState("")
    const { hub, openSearchPalette, setOpenSearchPalette, projects, setProjectStatusFilter, setOpenProjectModal } = useContext(ContextServiceApi)

    const [percentageCompleted, setPercentageCompleted] = useState(project?.percentageCompleted)

    const statuses = {
        pending: 'text-gray-800 bg-gradient-to-r from-amber-300 to-yellow-200 ring-1 ring-amber-600/20',
        running: 'text-gray-800 bg-gradient-to-r from-green-600 to-green-200 ring-1 ring-green-600/20',
        stopped: 'text-gray-800 bg-gradient-to-r from-red-500 to-red-200 ring-1 ring-slate-600/20',
        completed: 'text-gray-800 bg-gradient-to-r from-sky-600 to-slate-200 ring-1 ring-sky-600/20',
    }


    /* useEffect(()=>{
        hub?.on("projectChange", (response) => {
            console.log("projectChange in RunState Comp.")
            if (response.name == props.project.name){
                setProject(response)
            }
        });
    },[hub]) */

    useEffect(() => {

        //do {
        //  setTimeout(() => {
        if (project) {
            project?.percentageCompleted && parseInt(project?.percentageCompleted) > 0 ?
                setPercentageCompleted(`${project?.percentageCompleted}%`) :
                setPercentageCompleted("")//n/a

            if (project?.isCompleted) {
                setState(`completed`)
                if (moment(project?.lastStopDate).isValid) {
                    const hrs = _.floor(moment.duration(moment(project?.lastStopDate).diff(project?.lastRunDate)).asHours())

                    const mins = _.floor(moment.duration(moment(project?.lastStopDate).diff(project?.lastRunDate)).asMinutes()) % 60

                    const secs = _.floor(moment.duration(moment(project?.lastStopDate).diff(project?.lastRunDate)).asSeconds()) % 60

                    if (hrs != 0 && mins != 0 && secs != 0) {
                        setElapsedTime(`${hrs != 0 ? `${hrs} hours` : ''} ${mins != 0 ? `${mins} mins` : ''} Duration`)
                    }
                }
            } else
                if (project?.isStopped) {
                    setState("stopped")
                } else
                    if (project?.isRunning) {
                        setState("running")
                        return;
                    } else
                        if (project?.haveError) {
                            setState("error")
                            return;
                        }
            //setRandKey(_.random(1, 1000))
            // console.log(randKey)
        }
        // }, 3000)
        // } while (!project)


    }, [project])

    useEffect(() => {
        setProject(props.project)
    }, [])


    return <div className='flex w-full flex-col justify-between gap-x-4 divide-y gap-y-2 divide-slate-400/50'>
        <div className='grid grid-cols-4'>
            {project?.lastRunDate ?
                <span className='grid col-span-3'>
                    <span className='text-[8pt] uppercase font-black'>Duration of scrape</span>
                    <span className='flex text-[8pt] gap-2'> <span>{moment.tz(project?.lastRunDate, moment.tz.guess()).format('hh:mm:ss A')}</span>
                        - <span className={`${!project?.lastStopDate && 'invisible'}`}>{moment.tz(project?.lastStopDate, moment.tz.guess()).format('hh:mm:ss A')}</span></span>
                </span>
                : <span className='grid col-span-3 uppercase'>no scrape data</span>}

            <span className='col-span-1 grid content-end justify-end text-[8pt] uppercase font-black'>TIMEZONE  {_.split(moment.tz.guess(), "/")[1]}</span>
        </div>
        <div className='flex flex-row flex-grow  justify-center place-items-center'>
            <div className='flex grow flex-col  leading-0'>
                <span className='flex'>
                    <span className='uppercase font-black text-[9pt]'>{state}</span>
                    {project?.haveError ? <span className='bg-black/0 ring-0 ring-gray-800/40 rounded-full p-0.5'>
                        <ExclamationTriangleIcon className="h-5 p-1 ml-5 w-5 text-[8pt] rounded-full bg-yellow-600 text-white animate-ping" aria-hidden="true" /></span> : <span />}
                </span>
            </div>
            <div
                className={classNames(
                    'relative flex grid-cols-2 place-items-center   rounded-r-md  px-0 !text-[7pt] font-medium  ring-inset gap-x-4 h-8 min-w-24'
                )}
            >
                <span className='h-6'>
                    {(project?.isStopped) && <span className='relative grid  justify-start content-center'>
                        {/*  <span className='ring-1 rounded-xl bg-white px-2'>STOPPED</span> */}
                        <span className='uppercase text-[6pt] font-bold'>{moment(project?.lastStopDate).isValid && moment.tz(project?.lastStopDate, moment.tz.guess()).fromNow()}</span>
                    </span>
                    }
                    {(project?.isRunning) && <span className='grid  justify-start content-centerr'>
                        {/* <span>RUNNING</span> */}
                        <span className='uppercase text-[6pt] font-bold'>{moment(project?.lastRunDate).isValid && moment.tz(project?.lastRunDate, moment.tz.guess()).fromNow()}</span>
                    </span>
                    }
                    {(project?.isCompleted) && <span className={`${elapsedTime ? 'block' : 'hidden'} grid  justify-start content-center h-full`}>
                        {/* <span>COMPLETED</span> */}
                        <span className='uppercase text-[6pt] font-bold text-ellipsis overflow-hidden '>{elapsedTime}</span>
                    </span>
                    }
                </span>

                <div className='relative grid grid-cols-2  overflow-hidden place-items-center'>

                    {(project?.isCompleted == false && props.project?.isStopped == false && props.project?.isRunning == false) ? <span className='grid  place-items-center'>
                        <span>WAITING</span>
                    </span>
                        : <span className='text-center col-span-2 min-w-[100px] overflow-hidden '>
                            <span className={`${elapsedTime ? 'block' : ''} p-1 font-black text-lg`}>{percentageCompleted}</span></span>
                    }
                </div>
            </div>
        </div >
        {props.shownInList &&
            <div className='w-full h-6 hover:text-gray-200 cursor-pointer  px-4 text-[8pt] justify-center place-items-center overflow-hidden text-ellipsis'
                onClick={() => { alert(project?.msg) }}>
                {project?.msg}
            </div>
        }
    </div>
}


import { CheckIcon, HandThumbUpIcon, UserIcon } from '@heroicons/react/20/solid'
import { ContextServiceApi } from '../context/ContextServiceApi'
import { useContext, useEffect, useState } from 'react'
import { FolderOpenIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import _ from 'lodash' 
import moment from 'moment'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProjectNavList() {

    const { projects, openProjectModal, setOpenProjectModal, startDate, setDateFilter, projectFetchingInProgress } = useContext(ContextServiceApi)
    const [projectNavItems,setProjectNavItems] = useState([])

    useEffect(()=>{
        setProjectNavItems(_.orderBy(projects,['dateCreated'],['desc']))
    },[projects])

    return (
        <div className="flex flex-col">
            <div className=' flex w-full z-10 ml-[5px] mt-1  w-1 px-3  center text-white  uppercase font-thin text-xl py-0'>
                {projectFetchingInProgress ?
                    'Fetching Projects' :

                    projectNavItems.length == 0 ?
                    'No Projects to show' :
                    <span className='flex'>
                        <FolderOpenIcon className='h-8  text-sky-400' />
                        <span className='bg-sky-400/80 mx-2 px-1 w-8 h-8 grid place-items-center rounded-full'>{`${typeof projectNavItems !== 'undefined' ? projectNavItems?.length : 0}`}</span>Projects</span> 
                }
            </div>
            <ul role="list" className="grow mt-3 py-8 px-1 space-y-2 h-1 overflow-y-auto">
                {
                    projectNavItems?.map &&
                    projectNavItems?.map((project, projectIdx) => (
                        <li key={projectIdx} onClick={() => setOpenProjectModal(true, project)}  className="relative isolate h-12  rounded-none  border-l-0 pl-2 group">
                           <span className={`absolute h-full w-1 z-10 -left-0  opacity-0 group-hover:opacity-100 group-hover:animate-rotate-x bg-gradient-to-t to-gray-900/20 ${project.haveError ? 'from-yellow-400' :  project.isCompleted ? ' from-sky-600' : `${project.isStopped ? ' from-red-500' : ' from-gray-200'}`}`}/>
                           <span className={`absolute h-full w-1 z-5 -left-0   group-hover:animate-fade-left animate-delay-0  bg-gradient-to-b to-gray-900/20 ${project?.isRunning && 'animate-bounce'}  ${project.haveError ? 'from-yellow-400' :   project.isCompleted ? ' from-sky-600' : `${project.isStopped ? ' from-red-500' : 'from-gray-200'}`}`}/>
                            <div className="relative pb-0 h-full m-0 bg-black/10 rounded-none overflow-hidden  cursor-pointer group">
                                {projectIdx !== projects?.length - 1 ? (
                                    <span className="absolute left-4 top-4  h-full w-0.5 bg-black" aria-hidden="true" />
                                ) : null}
                                
                                <div className="relative flex h-full space-x-3  place-items-center pr-2  group-hover:bg-gradient-to-r from-sky-700 to-green-400">
                                    <div>
                                        <img
                                            src="./Round TikTok.svg"
                                            alt={project?.name}
                                            className="h-8 w-8 flex-none rounded-lg bg-black object-cover ring-1 ring-gray-900/10"
                                        />
                                    </div>
                                    <div className="flex grow min-w-0  place-items-center">
                                        <p className="text-xs uppercase text-white group-hover:text-sky-100">
                                            {project?.name}
                                        </p>
                                    </div>
                                    <ChevronRightIcon className='w-4 text-white' />
                                </div>
                                
                            </div>
                        </li>
                    ))}
            </ul>

        </div>
    )
}

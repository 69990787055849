import axios from 'axios';
import { appusers_path, api_path } from './config'
import { apiData } from '../api';
import * as authHandler from '../handlers/authHandler'
import _ from 'lodash';

export async function GetFilters(data, user) {

    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            // console.log(user?.signInUserSession?.idToken?.jwtToken)
        }

        axios({
            url: api_path + 'Settings/Global/Filters/Get',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            params: {}

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}

export async function UpdateFilters(data, user) {

    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            // console.log(user?.signInUserSession?.idToken?.jwtToken)
        }

        axios({
            url: api_path + 'Settings/Global/Filters/Update',
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            data: data

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}

export async function GetConfig(data, user) {

    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            // console.log(user?.signInUserSession?.idToken?.jwtToken)
        }

        axios({
            url: api_path + 'Settings/Global/Config/Get',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            data: data,
            params: {}

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}

export async function UpdateConfig(data, user) {

    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            // console.log(user?.signInUserSession?.idToken?.jwtToken)
        }

        axios({
            url: api_path + 'Settings/Global/Config/Update',
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken,
                //"content-type": "application/json"
            },
            data: data

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}

export async function GetProjectFilters(data, user) {

    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            //  console.log(user?.signInUserSession?.idToken?.jwtToken)
        }

        axios({
            url: api_path + 'Settings/Project/Filters/Get',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            data: data,
            params: { projectName: data?.name }

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}

export async function GetProjectConfig(data, user) {
    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            //  console.log(user?.signInUserSession?.idToken?.jwtToken)
        }

        axios({
            url: api_path + 'Settings/Project/Config/Get',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            data: data,
            params: { projectName: data?.name }
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}

export async function GetPermissions(user) {
    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            //  console.log(user?.signInUserSession?.idToken?.jwtToken)
        }

        let userRole = _.first(_.get(user?.signInUserSession?.accessToken?.payload, "cognito:groups"))

        console.log(userRole)
        if (userRole) {
            axios({
                url: api_path + 'Settings/Group/Permissions',
                headers: {
                    authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
                },
                params: { groupName: userRole }
            }).then((res) => {
                resolve(res)
            }).catch((err) => { resolve(err) })
        } else {
            // reject(false)
        }

    });
}


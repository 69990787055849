import { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Dialog, Transition, RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import {
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    DocumentIcon,
    FolderArrowDownIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    FolderArrowDown,
    FolderOpenIcon,
    XMarkIcon,
    DocumentMagnifyingGlassIcon,
    ExclamationCircleIcon
} from '@heroicons/react/24/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import moment from 'moment/moment'
import { ContextServiceApi } from '../context/ContextServiceApi'
import FilterPanel from './FilterPanel'
import _ from 'lodash'
import { CheckboxField } from '@aws-amplify/ui-react'
import GlobalFilterPanel from './GlobalFilterPanel'
import GlobalConfigPanel from './GlobalConfigPanel'

function CheckIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
            <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export function AdminMenuGlobalConfigDialog(props) {


    const [open, setOpen] = useState(props.windowState())
    const { updateConfig, configSettings, getConfig} = useContext(ContextServiceApi)

    const [defaultPlan, setDefaultPlan] = useState(null)
    const [newPlan, setNewPlan] = useState({})
    const [selected, setSelected] = useState({})
    const [projectName, setProjectName] = useState("")
    const [projectFile, setProjectFile] = useState(null)

    

    const [submitStatus, setSubmitStatus] = useState(null)
    const [filterPanel, setFilterPanel] = useState(null)
    const [projectFilterList, setProjectFilterList] = useState([])

    const [updatedConfig, setUpdatedConfig] = useState([])
    const [saved, setSaved] = useState(null)
    const [failed, setFailed] = useState(null)



    function closeModal() {
        setOpen(false)

    }

    function openModal() {
        setOpen(true)
    }

    function handleProjectNameChange(event) {
        setProjectName(event.target.value)
    }
    const handleProjectFileChange = (e) => {
        //console.log(e)
        if (e.target.files) {
            setProjectFile(e.target.files[0]);
        }
    };

    

    function submitDefaultConfig(event) {

        event.preventDefault()
        let data = (updatedConfig)
        //console.log(data)
        updateConfig(data).then((res) => {
            
            if (res.success) {
                setSaved({ success: res.success, message: res.msg })
                getConfig().then(() => {
                    setTimeout(() => {
                        setSaved(null)
                        props.closeWindow()
                    }, 2500)
                })
            } else {
                setFailed({ success: res.success, message: res.msg })
                setTimeout(() => {
                    setFailed(null)
                    props.closeWindow()
                }, 3500)
            }
        })
    }

    
    function onConfigChange(config) {
        setUpdatedConfig(config)
    }

    useEffect(() => {
        setOpen(props.windowState())
    }, [props.windowState()])

    useEffect(() => {
        if (open) {
            getConfig()
        }
    }, [open])

    useEffect(() => {
        //console.log(configSettings)
    }, [configSettings])




    return (
        <>

            <Transition.Root show={props.windowState()} as={Fragment}>
                <Dialog static as="div" className="relative z-10" onClose={() => null} >
                    <div className="fixed  will-change-auto animate-fade-right inset-0 bg-slate-800/20 backdrop-blur" />
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute  inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    {saved &&
                                            <div className='absolute grid place-items-center bottom-0 right-0 z-10 w-full h-full inset-y-0 bg-black/20'>
                                                <div className='animate-jump-in animate-once animate-delay-0 animate-ease-in-out w-64 h-64 grid place-items-center ring-1 ring-green-400 shadow-xl bg-white rounded-xl'>
                                                    <div className='w-36 h-36'>
                                                        <CheckCircleIcon className='animate-spin animate-once animate-delay-500 text-green-400' />
                                                    </div>
                                                    <p className='animate-wiggle animate-once animate-delay-500 animate-ease-in-out text-3xl text-green-700'>SAVED!</p>
                                                </div>
                                            </div>
                                        }
                                        {failed &&
                                            <div className='absolute grid place-items-center bottom-0 right-0 z-10 w-full h-full inset-y-0 bg-black/20'>
                                                <div className='animate-jump-in animate-once animate-delay-0 animate-ease-in-out w-64 h-64 grid place-items-center ring-1 ring-green-400 shadow-xl bg-white rounded-xl'>
                                                    <div className='w-36 h-36'>
                                                        <ExclamationCircleIcon className='animate-jump animate-once  animate-delay-500 text-red-400' />
                                                    </div>
                                                    <p className='animate-flip-down animate-once animate-delay-500 animate-ease-in-out text-3xl text-red-700'>FAILED!</p>
                                                </div>
                                            </div>
                                        }
                                        <div  className="flex h-full pt-0 flex-col divide-y divide-gray-200 bg-slate-800 shadow-2xl shadow-slate-500 ">
                                            <div className="flex flex-col px-0 h-16 mt-1 py-4">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-base uppercase font-semibold leading-6 text-white">
                                                        <div className="flex items-center gap-x-4  px-1">
                                                            <div className="flex-grow text-xl truncate font-thin leading-6 indent-5 text-sky-500">Manage Default Configurations</div>

                                                        </div>
                                                    </Dialog.Title>
                                                    <div className="mx-3 flex h-fit items-center">
                                                        <button
                                                            type="button"
                                                            className="relative rounded-md bg-red-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => props.closeWindow()}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between py-0 overflow-y-auto">
                                                <GlobalConfigPanel setProjectConfigList={onConfigChange} />
                                            </div>
                                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                    onClick={() => props.closeWindow()}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={submitDefaultConfig}   
                                                    className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
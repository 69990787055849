import axios from 'axios';
import { appusers_path, api_path } from './config'
import { apiData } from '../api';
import * as authHandler from '../handlers/authHandler'

export async function ProjectGet(data, user) {

    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            //  console.log(user?.signInUserSession?.idToken?.jwtToken)
        }

        if (data?.startDate === null || data?.startDate === null) {
            reject("missing date filters")
        }

        axios({
            url: api_path + 'Project/get',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            data: data,
            params: { startDate: data?.startDate, endDate: data?.endDate }

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}
export async function ProjectCreate(data, user) {

    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            // console.log(user?.signInUserSession?.idToken?.jwtToken)
        }

        axios({
            url: api_path + 'Project/create',
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken,
                "Content-Type": "multipart/form-data",
            },
            data: data

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}
export async function ProjectDelete(data, user) {

    return new Promise((resolve, reject) => {

        if (!user) { resolve(false); return; } else {
            //  console.log(user?.signInUserSession?.idToken?.jwtToken)
        }
        axios({
            url: api_path + 'Project/delete',
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            params: { projectName: data?.name }

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}
export function ProjectDownload(data, user, callBack,abortController) {

    let statusMsg = "starting"
    let responseObj = {}
    return axios({
        url: api_path + 'Project/download',
        responseType: 'blob',
        signal: abortController.signal,
        onDownloadProgress(progressEvent) {
            callBack(() => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); // you can use this to show user percentage of file downloaded
                responseObj = { statusMsg: statusMsg, loaded: progressEvent.loaded, total: progressEvent.total, percentage: percentCompleted, projectName: data?.name }
                return responseObj
            })
            statusMsg = "downloading"

        },
        headers: {
            authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
        },
        data: data,
        params: { projectName: data?.name }

    }).then((res) => {
        responseObj.statusMsg = "completed"
        callBack(() => { return responseObj })
        return res.data
    }).catch((err) => {
        if (axios.isCancel(err)) {
            console.error('Operation canceled');
        } else {
            // handle HTTP error...
        }
        responseObj.statusMsg = "stopped"
        callBack(() => { return responseObj })
        return err
    })

    /* return new Promise((resolve, reject) => {
        if (!user) { resolve(false); return; } else {
         //  console.log(user?.signInUserSession?.idToken?.jwtToken)
        } });*/
}



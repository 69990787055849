import _ from 'lodash'
const url = window.location.host
const production = _.indexOf(_.words(url), 'www') != -1
console.log(production, _.words(url))

const baseUrl = `https://d1bei5fskh4t87.cloudfront.net${production ? '':'/staging'}` //'http://api.socialspotlight.io'//'http://18.218.64.202'

export const appuser_path = `${baseUrl}/common/`
export const api_path = `${baseUrl}/api/`
export const hub_path = `${baseUrl}/hubs`

import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    FolderOpenIcon,
    TrashIcon,
    XMarkIcon,
    FolderArrowDownIcon,
} from '@heroicons/react/24/outline'
import {
    StopCircleIcon
} from '@heroicons/react/24/solid'
import { ContextServiceApi } from '../context/ContextServiceApi'
import _ from 'lodash'


export default function DownloadProgressBar(props) {
    const { projects, fileDownloadData, fileData, cancelFileDownLoad, getProjects, deleteProject, downloadProject, tikTokRun, tikTokReRun, tikTokStop,
        selectedProject, openProjectModal, setOpenProjectModal, getProjectFilters, getProjectConfig, setShowStartPrompt, setShowReStartPrompt } = useContext(ContextServiceApi)

    const [dialog, showDialog] = useState(false)

    function openFiles() {
        console.log(fileData)
        const href = URL.createObjectURL(fileData);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${selectedProject?.name}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    function confirmCancel() {

        cancelFileDownLoad()
        showDialog(false)
    }

    return (
        <>

            <div className='relative w-full h-12 bg-slate-800 text-white'>
                <span className={`absolute h-12 bg-sky-400`} style={{
                    width: fileDownloadData?.percentage + '%'
                }} />

                <span className='absolute z-10 w-full h-full bg-transparent grid grid-cols-4 place-items-center'>
                    <span className='col-span-1'>{fileDownloadData?.percentage}% </span>
                    <span className='col-span-2 flex space-x-1'>
                        <span className='w-16 flex  justify-end'>{_.ceil(fileDownloadData?.loaded / 1000000, 2)}</span>
                        <span>/</span>
                        <span className='w-16  flex justify-start'>{_.ceil(fileDownloadData?.total / 1000000, 2)}</span>
                        <span>MB</span>
                    </span>
                    <span className='col-span-1 h-full border-left-1 border-left-white p-1 '>
                        {(fileDownloadData?.loaded > 0 && fileDownloadData?.loaded == fileDownloadData?.total) ?
                            <button onClick={() => openFiles()} type='button' className='animate-jump animate-once animate-delay-0 animate-ease-in-out col-span-1 grid place-items-center py-1 w-full flex space-x-1 text-black hover:text-gray-200 rounded'>
                                <FolderArrowDownIcon className='w-5 animate-bounce' />
                                <span className='text-[6pt] font-bold'>Open Files</span>
                            </button>
                            : <button onClick={() => showDialog(true)} type='button' className='animate-jump animate-once animate-delay-0 animate-ease-in-out col-span-1 grid place-items-center w-full flex  text-red-400 hover:text-red-600 rounded'>
                                <StopCircleIcon className='w-6' />
                                <span className='text-[6pt] font-bold'>Abort</span>
                            </button>
                        }
                    </span>
                    {dialog &&
                        <span className='absolute right-0 flex flex-col h-full w-48 bg-slate-600/20 backdrop-blur-sm border-2 border-red-400/50 ring-2 ring-red-300 rounded'>
                            <span className='text-xs bg-red-400/20 w-full text-center'>ABORT</span>
                            <span className='grid grid-cols-2 text-xs  grow w-full place-items-center'>
                                <button type='button' onClick={() => confirmCancel()} className='w-full h-full col-span-1 bg-red-800/50 hover:bg-red-800/80'>YES</button>
                                <button type='button' onClick={() => showDialog(false)} className='w-full h-full col-span-1 hover:bg-gray-200/20'>NO</button>
                            </span>

                        </span>
                    }
                </span>
            </div>

        </>
    )

}
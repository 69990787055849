import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext, useEffect, useState } from 'react'
import { ContextServiceApi } from '../context/ContextServiceApi'
import { Divider } from '@aws-amplify/ui-react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

export default function StartPrompt() {
    const { showReStartPrompt, setShowReStartPrompt,  tikTokReRun, selectedProject, getProjects, setOpenProjectModal } = useContext(ContextServiceApi)

    function closeModal() {
        setShowReStartPrompt(false)
    }

    function openModal() {
        setShowReStartPrompt(true)
    }


    function submitTikTokReRun(event) {
        tikTokReRun(selectedProject).then((res) => {
            console.log(res)
            setShowReStartPrompt(false)
            setTimeout(() => {
                setOpenProjectModal(false)
                getProjects()
            }, 200)

        })
    }

    return (
        <>
            <Transition appear show={showReStartPrompt} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={()=>{}}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        {selectedProject?.name}
                                    </Dialog.Title>
                                    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                                        
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3">
                                                <p className="text-sm text-yellow-700">
                                                    progress will start over and all files generated will be removed. Confirm to Restart the scraping process
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-3 gap-x-4 mt-4 space-between">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-slate-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <span />
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => submitTikTokReRun()}
                                        >
                                            Yes, Continue
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    FolderOpenIcon,
    HomeIcon,
    UserCircleIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import MainNav from '../components/MainNav'
import ProjectNavList from '../components/ProjectNavList'
import { ContextServiceApi } from '../context/ContextServiceApi'
import { ContextSignalR } from '../context/ContextSignalR'
import ProjectCardWindow from '../components/ProjectCardWindow'
import { NewProject } from '../components/NewProject'
import StartPrompt from '../components/StartPrompt'
import ReStartPrompt from '../components/ReStartPrompt'
import UserAuthModule from '../components/UserAuthModule'

import { useAuthenticator } from '@aws-amplify/ui-react';
import _ from 'lodash'


const navigation = [
    { name: 'Projects', href: '#', icon: FolderIcon, current: true },
]

const userNavigation = [
    { name: 'Your profile', href: '#' },
    { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MainLayout(props) {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [sidebarKey, setSidebarKey] = useState(0)

    const { getConfig, getFilters, getPermissions, permissions, configSettings, configFilters, rebuildHub } = useContext(ContextServiceApi)

    const { user, authStatus, signOut } = useAuthenticator((context) => [context]);

    useEffect(() => {
        console.log(authStatus)
        switch (authStatus) {
            case 'authenticated': getConfig().then(() => {
            })
                getFilters().then(() => {
                })
                getPermissions().then(() => {
                })
                break;
            case 'configuring': break;
            case 'unauthenticated': break;
        }








        //  rebuildHub()

    }, [authStatus])

    useEffect(() => {
        setSidebarKey(_.random(Number.MAX_VALUE))
    }, [sidebarOpen])

    return (
        <>
            <UserAuthModule />
            <StartPrompt />
            <ReStartPrompt />
            <div key={sidebarKey} className='bg-gradient-to-l from-sky-400 via-blue-300/50 to-blue-400'>

                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    {/* Sidebar component, swap this element with another sidebar if you like */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <img
                                                className="h-8 w-auto"
                                                src="./logo.png"
                                                alt="Social SpotLight"
                                            />
                                            <p className='text-white uppercase'>Social Spotlight</p>
                                        </div>
                                        <nav className="flex flex-1 flex-col">
                                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                                <li>
                                                    <ul role="list" className="-mx-2 space-y-1">

                                                        {navigation.map((item) => (
                                                            <li key={item.name}>
                                                                <a
                                                                    href={item.href}
                                                                    className={classNames(
                                                                        item.current
                                                                            ? 'bg-gray-800 text-white'
                                                                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                    )}
                                                                >
                                                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                                    {item.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                                <li className="mt-auto">
                                                    <a
                                                        href="#"
                                                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                                    >
                                                        <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                        Setting
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-hidden bg-gray-900 px-3 pb-4">

                        <div className="flex h-28 mt-6 shrink-0 place-items-start">
                            <div className="grow grid h-16 shrink-0 place-items-center">
                                <img
                                    className="h-24 rounded-xl "
                                    src="./logo.png"
                                    alt="Social SpotLight"
                                />
                                <p className='text-white uppercase'>Social Spotlight</p>
                            </div>
                            <div className="hidden grid h-16 shrink-0 place-items-start">
                                <span className='text-white font-thin text-3xl uppercase'>Social</span>
                                <span className='text-cyan-400 font-thin text-xl uppercase'>Spotlight</span>
                            </div>
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                <li className='grid grid-cols-4 place-items-center'>
                                    <span className='col-span-4 w-full'> <NewProject nav={true} /> </span>
                                </li>
                                <li className='grid h-1 grow overflow-hidden bg-gradient-to-tr from-slate-800 to-sky-800 rounded-xl ring-1 ring-indigo-600/50'>
                                    <ProjectNavList />
                                </li>

                                <li className="mt-auto h-16" >

                                    <div
                                        className={` ${authStatus == 'authenticated' ? 'visible' : 'invisible'} group -mx-2 grid grid-cols-6 gap-y-3  rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white`}
                                    >
                                        <div className='col-span-1 grid content-center'>
                                            <UserCircleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                        </div>
                                        <h1 className='col-span-5'>{user?.attributes?.email}</h1>
                                        <button className='col-span-6 ring-1 rounded bg-slate-500' onClick={signOut}>Sign out</button>

                                    </div>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="flex flex-col h-screen lg:pl-72">

                    <MainNav />

                    <main className="py-10 grow h-1 overflow-auto">

                        <div className="grid px-4 sm:px-6 lg:px-8 justify-center">{props.children}</div>
                    </main>
                </div>

                <ProjectCardWindow />
            </div>


        </>
    )
}

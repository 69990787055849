import { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Dialog, Transition, RadioGroup } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import {
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    DocumentIcon,
    FolderArrowDownIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    FolderArrowDown,
    FolderOpenIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import moment from 'moment/moment'
import { ContextServiceApi } from '../context/ContextServiceApi'
import FilterPanel from './FilterPanel'
import _ from 'lodash'
import { CheckboxField } from '@aws-amplify/ui-react'


function CheckIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
            <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}



export function NewProject(props) {

    const [open, setOpen] = useState(false)
    const { newProjectDialog, setNewProjectDialog, getProjects, permissions, createProject, configSettings, configFilters } = useContext(ContextServiceApi)

    const [defaultPlan, setDefaultPlan] = useState(null)
    const [newPlan, setNewPlan] = useState({})
    const [selected, setSelected] = useState({})
    const [projectName, setProjectName] = useState("")
    const [projectFile, setProjectFile] = useState(null)

    const [newPlan_url, setNewPlan_url] = useState("")
    const [newPlan_apiKey, setNewPlan_apiKey] = useState("")
    const [newPlan_apiAccountKey, setNewPlan_apiAccountKey] = useState("")

    const [submitStatus, setSubmitStatus] = useState(null)
    const [filterPanel, setFilterPanel] = useState(null)
    const [projectFilterList, setProjectFilterList] = useState([])



    function closeModal() {
        setNewProjectDialog(false)
    }

    function openModal() {
        setProjectName("")
        setProjectFile(null)
        setNewPlan_url("")
        setNewPlan_apiKey("")
        setNewPlan_apiAccountKey("")
        setSelected(defaultPlan)
        setNewProjectDialog(true)
    }

    function handleProjectNameChange(event) {
        setProjectName(event.target.value)
    }
    const handleProjectFileChange = (e) => {
        //console.log(e)
        if (e.target.files) {
            setProjectFile(e.target.files[0]);
        }
    };

    function handleNewPlan_urlChange(event) {
        setNewPlan_url(event.target.value)
    }
    function handleNewPlan_apiKeyChange(event) {
        setNewPlan_apiKey(event.target.value)
    }
    function handleNewPlan_apiAccountKeyChange(event) {
        setNewPlan_apiAccountKey(event.target.value)
    }

    function submitNewProject(event) {

        event.preventDefault()

        //console.log(selected)

       // return 
        const formData = new FormData();

        // Update the formData object
        formData.append('Config.ProjectName', projectName);
        formData.append('Config.ApiBaseUrl', selected?.ApiBaseUrl);
        formData.append('Config.ApiKey', selected?.ApiKey);
        formData.append('Config.ApiAccountKey', selected?.ApiAccountKey);
        formData.append('Config.Count', 0);
        formData.append('Config.SetAsDefault', false);
        formData.append('FilterText', JSON.stringify(projectFilterList));
        formData.append("file", projectFile);
        createProject(formData).then((res) => {
            //console.log(res)
            setSubmitStatus(res)
        }).then(() => {
            getProjects()
            setNewProjectDialog(false)
        })
    }

    function callBackUpdateFilter(updatedFilters) {
        alert(updatedFilters)
    }

    useEffect(() => {
        setTimeout(() => {
            setDefaultPlan({
                isDafault: true,
                ApiBaseUrl: configSettings?.apiBaseUrl,
                ApiKey: configSettings?.apiKey,
                ApiAccountKey: configSettings?.apiAccountKey
            })
            //console.log(configSettings)

            setSelected(defaultPlan)
        }, 100)

    }, [configSettings])


    useEffect(() => {
        setTimeout(() => {
            //console.log(defaultPlan)
        }, 100)
    }, [defaultPlan])

    useEffect(() => {

        setNewPlan({
            isDefault: false,
            ApiBaseUrl: newPlan_url,
            ApiKey: newPlan_apiKey,
            ApiAccountKey: newPlan_apiAccountKey,
        })
    }, [newPlan_url, newPlan_apiKey, newPlan_apiAccountKey])

    useEffect(() => {
        if (submitStatus?.success == false)
            alert(submitStatus.msg)
    }, [submitStatus])

    useEffect(() => {
        /* setTimeout(() => {
            if (newProjectDialog) {
                setProjectFilterList(configFilters)
                setFilterPanel(<FilterPanel project={props.project} configFilters={configFilters} setProjectFilterList={setProjectFilterList} />)
            } else {
                setFilterPanel(null)
            }
        }, 100) */

    }, [newProjectDialog])





    return (
        <>
            <div className="inset-0 grow w-full flex items-center justify-center">
                {props.nav ? <button
                    type="button"
                    onClick={openModal}
                    className={` ${permissions?.canCreateProject ? 'block' : 'hidden'} flex  w-full grow items-center rounded bg-slate-800 px-3 py-2 text-sm font-semibold text-sky-400 shadow-sm hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600`}
                >
                    <span className='grow'>New Project</span>
                    <PlusIcon className='w-8'/>
                </button> :
                    <button
                        type="button"
                        onClick={openModal}
                        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                            />
                        </svg>
                        <span className="mt-2 block text-sm font-semibold text-gray-900">Create a new project</span>
                    </button>
                }
            </div>
            <Transition.Root show={newProjectDialog} as={Fragment}>
                <Dialog static as="div" className="relative z-10" onClose={() => null} >
                    <div className="fixed inset-0" />
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute  inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <form onSubmit={submitNewProject} className="flex h-full pt-0 flex-col divide-y divide-gray-200 bg-white shadow-2xl shadow-slate-500 ">
                                            <div className="flex flex-col px-0 h-16 mt-1 py-4 bg-sky-800">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-base uppercase font-semibold leading-6 text-white">
                                                        <div className="flex items-center gap-x-4  px-1">
                                                            <div className="flex-grow text-xl truncate font-thin leading-6 indent-5 text-sky-500">Start New Project</div>

                                                        </div>
                                                    </Dialog.Title>
                                                    <div className="mx-3 flex h-fit items-center">
                                                        <button
                                                            type="button"
                                                            className="relative rounded-md bg-red-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => setNewProjectDialog(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between py-4 overflow-y-auto bg-slate-200">
                                                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                                    <div className="overflow-hidden bg-slate-0  ring-0 ring-zinc-0 sm:rounded-md">
                                                        <ul role="list" className="divide-y divide-gray-0">
                                                            <li className="px-4 py-4 sm:px-6 min-h-32">
                                                                <div className="bg-white shadow sm:rounded-lg">
                                                                    <div className="px-4 py-5 sm:p-6">

                                                                        <input
                                                                            value={projectName}
                                                                            onChange={handleProjectNameChange} placeholder='Project Name' className='w-full text-black rounded-lg' />
                                                                    </div>
                                                                </div>

                                                            </li>
                                                            <li className="px-4 py-4 sm:px-6 min-h-32">
                                                                <div className="bg-white shadow sm:rounded-lg">
                                                                    <div className="px-4 py-5 sm:p-6">
                                                                        <h3 className="text-base font-semibold leading-6 text-gray-900">Upload your CSV File</h3>

                                                                        <div className="mt-5 sm:flex sm:items-center">
                                                                            <div className="w-full sm:max-w-xs">

                                                                                <label htmlFor="file" className="sr-only">
                                                                                    CSV File
                                                                                </label>
                                                                                <input
                                                                                    id="file"
                                                                                    type="file"
                                                                                    onChange={handleProjectFileChange}
                                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </li>
                                                            <li className="bg-black/10 rounded-lg  px-4 py-4 sm:px-6 min-h-32">
                                                                <div className="mx-auto w-full max-w-md">
                                                                    <RadioGroup value={selected} onChange={setSelected}>
                                                                        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                                                                        <div className="space-y-2">

                                                                            <RadioGroup.Option
                                                                                value={defaultPlan}
                                                                                className={({ active, checked }) =>
                                                                                    `${active ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300' : ''}
                                                                                             ${checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white'}
                                                                                             relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                                                                                }
                                                                            >
                                                                                {({ active, checked }) => (
                                                                                    <>
                                                                                        <div className="flex w-full items-center justify-between">
                                                                                            <div className="flex flex-col items-start">
                                                                                                <div className="text-sm font-bold text-sky-500">Default Config</div>
                                                                                                <div className="flex flex-col text-sm">
                                                                                                    <RadioGroup.Label
                                                                                                        as="div"
                                                                                                        className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'
                                                                                                            }`}
                                                                                                    >
                                                                                                        {configSettings?.apiBaseUrl}
                                                                                                    </RadioGroup.Label>
                                                                                                    <RadioGroup.Description
                                                                                                        as="div"
                                                                                                        className={`flex grow flex-col gap-4 ${checked ? 'text-sky-100' : 'text-gray-500'
                                                                                                            }`}
                                                                                                    >

                                                                                                    </RadioGroup.Description>
                                                                                                </div>
                                                                                            </div>
                                                                                            {checked && (
                                                                                                <div className="shrink-0 text-white">
                                                                                                    <CheckIcon className="h-6 w-6" />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </RadioGroup.Option>
                                                                            <RadioGroup.Option
                                                                                value={newPlan}
                                                                                className={({ active, checked }) =>
                                                                                    `${active ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300' : ''}
                                                                                             ${checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white'}
                                                                                             relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                                                                                }
                                                                            >
                                                                                {({ active, checked }) => (
                                                                                    <>
                                                                                        <div className="flex w-full items-center justify-between">
                                                                                            <div className="flex flex-col items-center">
                                                                                                <p> Set New Configurations </p>
                                                                                                {
                                                                                                    checked &&
                                                                                                    <div className="text-sm">
                                                                                                        <RadioGroup.Label
                                                                                                            as="div"
                                                                                                            className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'
                                                                                                                }`}
                                                                                                        >
                                                                                                        </RadioGroup.Label>
                                                                                                        <RadioGroup.Description
                                                                                                            as="div"
                                                                                                            className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'
                                                                                                                }`}
                                                                                                        >
                                                                                                            <span>{newPlan.SetAsDefault}</span>
                                                                                                        </RadioGroup.Description>
                                                                                                    </div>
                                                                                                }

                                                                                            </div>
                                                                                            {checked && (
                                                                                                <div className="shrink-0 text-white">
                                                                                                    <CheckIcon className="h-6 w-6" />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </RadioGroup.Option>
                                                                        </div>
                                                                    </RadioGroup>
                                                                    {(selected?.isDafault) ?
                                                                        <div className='grid p-4 my-4 gap-5 rounded-xl  text-gray-500 text-sm'>
                                                                            <div className='relative flex h-20 flex-wrap w-full  overflow-hidden  border-b border-slate-400'>
                                                                                <h2 className='text-xs font-bold'>DEFAULT API KEY</h2>
                                                                                <div className='absolute top-5 flex flex-wrap w-full  overflow-hidden hover:overflow-auto hover:text-slate-700 py-3'>
                                                                                    <div className='text-wrap'>
                                                                                        {configSettings?.apiKey}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='relative flex h-20 flex-wrap w-full  overflow-hidden'>
                                                                                <h2 className='text-xs font-bold'>DEFAULT API ACCOUNT KEY</h2>
                                                                                <div className='absolute top-5 flex flex-wrap w-full  overflow-hidden hover:overflow-auto hover:text-slate-700 py-3'>
                                                                                    <div className='text-wrap'>
                                                                                        {configSettings?.apiAccountKey}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> :
                                                                        <div className='flex flex-col space-y-2 bg-white p-2 my-3 rounded shadow-xl'>
                                                                            <label for='newPlan_url' className='font-black text-gray-500 text-xs indent-2'>URL</label>
                                                                            <input id='newPlan_url' value={newPlan_url} onChange={handleNewPlan_urlChange} placeholder='ENTER BASE API URL' className='text-black rounded-lg  border-gray-300 hover:border-gray-400' />
 
                                                                            <label for='newPlan_apiKey' className='font-black text-gray-500 text-xs indent-2'>API KEY</label>
                                                                            <input id='newPlan_apiKey' value={newPlan_apiKey} onChange={handleNewPlan_apiKeyChange} placeholder='ENTER API KEY' className='text-black rounded-lg  border-gray-300 hover:border-gray-400' />

                                                                            <label for='newPlan_apiAccountKey' className='font-black text-gray-500 text-xs indent-2'>API ACCOUNT KEY</label>
                                                                            <input id='newPlan_apiAccountKey' value={newPlan_apiAccountKey} onChange={handleNewPlan_apiAccountKeyChange} placeholder='ENTER API ACCOUNT KEY' className='text-black rounded-lg border-gray-300 hover:border-gray-400' />


                                                                        </div>
                                                                    }
                                                                </div>
                                                                <FilterPanel setProjectFilterList={setProjectFilterList}/>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                    onClick={() => setNewProjectDialog(false)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"

                                                    className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
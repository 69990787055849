import React from "react";
import { ContextServiceApi } from "./ContextServiceApi";
import _, { forEach } from "lodash";
import moment from "moment";
import * as authHandler from '../handlers/authHandler'
import *  as Service from '../services'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { appusers_path, api_path, hub_path } from '../services/config'


function getDaysOfMonth(year, month) {
  var monthDate = moment(year + '-' + month, 'YYYY-MM');
  var daysInMonth = monthDate.daysInMonth();
  var arrDays = [];

  while (daysInMonth) {
    var current = moment(year + '-' + month, 'YYYY-MM').date(daysInMonth);
    arrDays.push(current.format('YYYY-MM-DD'));
    daysInMonth--;
  }

  return arrDays;
}

/* function projectGeneratorRand() {
  let pArray = []



  forEach(getDaysOfMonth(2023, 7), (date, count) => {

    let tempPercent = _.ceil(_.random(30, 100, true), 2)
    let percentCond = tempPercent < 70 ? tempPercent : 100
    let isPauseCond = percentCond !== 100 && _.random(0, 1)

    pArray.push({
      id: count + 1,
      name: `Project ${date}`,
      dateCreated: date,
      lastRunDate: null,
      isRunning: percentCond !== 100 && !isPauseCond,
      isCompleted: percentCond === 100,
      isPaused: isPauseCond,
      canReRun: percentCond === 100,
      percentageCompleted: percentCond
    })
  })

  return _.orderBy(pArray, ['dateCreated'],
    ['asc'])
} */

export default class ProviderServiceApi extends React.Component {

  constructor(props) {
    super(props);

    this.setLoggedInUser = (user) => {

      this.setState({ loggedInUser: user })

    }

    this.setDateFilter = (date) => {

      const month = _.split(date)[0]
      const year = _.split(date)[1]

      this.setState({
        startDate: moment(`${year}-${month}-01`).format("YYYY-MM-DD")
      }, () => {
        this.setState({
          endDate: moment(this.state.startDate).endOf('month').format('YYYY-MM-DD')
        }, () => {
          setTimeout(() => {

            this.state.getProjects()
          }, 1000)
        })
      })

      

      setTimeout(() => {

      }, 500)

    }
    this.setOpenSearchPalette = (open, project) => {
      this.setState({ openSearchPalette: open }, () => {
      })
    }
    this.setOpenProjectModal = (open, project) => {
      this.setState({ openProjectModal: open }, () => {
        this.setState({ selectedProject: project }, () => {
          ////console.log({ open: open, project: project?.name })
          this.setState({ fileDownloadData: null })
        })
      })
    }
    this.getProjects = () => {


      this.setState({ projectFetchingInProgress: true })
      return new Promise((resolve, reject) => {
        //console.log(this.state.loggedInUser)
        Service.Project.ProjectGet({ startDate: this.state.startDate, endDate: this.state.endDate }, this.state.loggedInUser).then((res) => {
          ///resolve(res)
          _.forEach(res, (p) => { p._STATEKEY = p.name })
          //console.log(res)
          this.setState({
            projects: _.orderBy(res, ['dateCreated'],
              ['desc'])
          }, () => {
            this.setFilteredProjects()
          })
        }).catch((err) => {
          //console.log(err)
        }).finally(() => {
          setTimeout(() => {
            this.setState({ projectFetchingInProgress: false })
          }, 500)
        })
      })
    }
    this.createProject = (data) => {
      return new Promise((resolve, reject) => {
        Service.Project.ProjectCreate(data, this.state.loggedInUser).then((res) => {
          resolve(res)
          // this.setState({ projects: res })
        })
      })
    }
    this.deleteProject = (data) => {
      return new Promise((resolve, reject) => {
        Service.Project.ProjectDelete(data, this.state.loggedInUser).then((res) => {
          resolve(res)
          //this.setState({ projects: res })
          this.getProjects()
        })
      })
    }
    this.downloadProject = (data) => {


      //console.log(this.state.abortController)
      let callBack = (fx) => {
        if (this.state.abortController.signal.aborted) {
          this.setState({ abortController: new AbortController() })
        }
        this.setState({ fileDownloadData: { ...this.state.fileDownloadData, ...fx() } })
        //console.log(this.state.fileDownloadData?.statusMsg)
        if (this.state.fileDownloadData?.statusMsg == 'stopped') {
          this.setState({ fileData: null }, () => {
            this.setState({ fileDownloadData: null }, () => {
              //console.log("data cleared")
            })
          })
        }
      }
      let response = Service.Project.ProjectDownload(data, this.state.loggedInUser, callBack, this.state.abortController)

      return response
    }
    this.tikTokRun = (data) => {
      //console.log(data)
      return new Promise((resolve, reject) => {
        Service.TikTok.TikTokRun(data, this.state.loggedInUser).then((res) => {
          resolve(res)
        })
      })
    }
    this.tikTokReRun = (data) => {
      return new Promise((resolve, reject) => {
        Service.TikTok.TikTokReRun(data, this.state.loggedInUser).then((res) => {
          resolve(res)
        })
      })
    }
    this.tikTokStop = (data) => {
      return new Promise((resolve, reject) => {
        Service.TikTok.TikTokStop(data, this.state.loggedInUser).then((res) => {
          resolve(res)
        })
      })
    }

    this.getConfig = (data) => {
      return new Promise((resolve, reject) => {
        Service.Setting.GetConfig(data, this.state.loggedInUser).then((res) => {
          resolve(res)
          this.setState({ configSettings: res.settings })
        })
      })
    }

    this.getFilters = (data) => {
      return new Promise((resolve, reject) => {
        Service.Setting.GetFilters(data, this.state.loggedInUser).then((res) => {
          resolve(res)
          this.setState({ configFilters: res.filterTexts })
        })
      })
    }
    this.updateFilters = (data) => {
      return new Promise((resolve, reject) => {
        Service.Setting.UpdateFilters(data, this.state.loggedInUser).then((res) => {
          this.setState({ configFilters: res.filterTexts })
          resolve(res)
        })
      })
    }
    this.updateConfig = (data) => {
      return new Promise((resolve, reject) => {
        Service.Setting.UpdateConfig(data, this.state.loggedInUser).then((res) => {
          resolve(res)
        })
      })
    }
    this.getPermissions = () => {
      return new Promise((resolve, reject) => {
        Service.Setting.GetPermissions(this.state.loggedInUser).then((res) => {
          //console.log(res)
          this.setState({ permissions: res.data })
          resolve(res)

        })
      })
    }
    this.getProjectFilters = (data) => {
      return new Promise((resolve, reject) => {
        Service.Setting.GetProjectFilters(data, this.state.loggedInUser).then((res) => {
          resolve(res)
        })
      })
    }
    this.getProjectConfig = (data) => {
      return new Promise((resolve, reject) => {
        Service.Setting.GetProjectConfig(data, this.state.loggedInUser).then((res) => {
          resolve(res)
        })
      })
    }
    this.setShowStartPrompt = (open) => {
      this.setState({ showStartPrompt: open })
    }
    this.setShowReStartPrompt = (open) => {
      this.setState({ showReStartPrompt: open })
    }
    this.editProject = (projectName, response) => {
      let selectedProject = _.find(this.state.projects, { name: projectName })

      let projectToEdit = {
        name: response.name,
        canReRun: response.canReRun,
        dateCreated: response.dateCreated,
        haveError: response.haveError,
        isCompleted: response.isCompleted,
        isRunning: response.isRunning,
        isStopped: response.isStopped,
        isStopping: response.isStopping,
        lastRunDate: response.lastRunDate,
        lastStopDate: response.lastStopDate,
        msg: response.msg,
        percentageCompleted: response.percentageCompleted,
        _STATEKEY: selectedProject.name//_.ceil(_.random(Number.MAX_VALUE,true))
      }
      // console.log(response)
      this.setState({ projects: [..._.without(this.state.projects, selectedProject), projectToEdit], }, () => {
        ////console.log(projectToEdit)
        ////console.log(this.state.projects)
      })
    }
    this.closeConnection = () => {
      return new Promise((resolve, reject) => {
        this.setState({ connection: new HubConnectionBuilder() }, () => {
          setTimeout(() => {
            ////console.log(this.state.connection)
            resolve()
          }, 1500)
        });
      })

    }
    this.rebuildHub = () => {
      // this.connection = new HubConnectionBuilder();
      //console.log("Rebuild Hub")
      this.closeConnection().then(() => {

        //this.connection.withUrl(`${api_path}hubs/Parser { accessTokenFactory: () => authHandler.getAccessToken() }`)
        this.state.connection.withUrl(`${hub_path}/Parser`)
        this.state.connection.withAutomaticReconnect()
        //console.log(this.state.connection)
        ////console.log("one")
        let p = new Promise((resolve, reject) => {
          resolve(this.state.connection.build())
        })
        //const buildConn = this.connection.build();
        p.then((r) => {

          authHandler.setHubConnectionId(r.connection.connectionId)

          this.setState({ hub: r }, () => {
            this.state.hub.start({ withCredentials: true })
              .then(() => {
                //console.log("SignalR Connected!");
                //console.log(this.state.connection?.httpConnectionOptions.WebSocket)
                this.state.hub.on("projectChange", (response) => {
                  //console.log("projectChange")
                  // //console.log(response)
                  ////console.log(`Projects ${this.state.projects.length}`)
                  this.editProject(response.name, response)
                });
              })
              .catch((err) => {
                //console.log('SignalR connection Error: ' + err));
              });

          })
        }).catch((err) => {
          //console.log(err)
        })






      });
    }
    this.setConnectedState = () => {
      this.setState({ connectedState: this.state.hub?._connectionState })
    }
    this.setProjectStatusFilter = (STAT) => {
      this.setState({ projectStatusFilter: STAT.type ? STAT.type : STAT }, () => {
        // alert(this.state.projectStatusFilter)
      })

    }
    this.setFilteredProjects = () => {
      this.setState({
        filteredProjects:
          _.orderBy(_.filter(this.state.projects, (project) => {
            switch (this.state.projectStatusFilter) {
              case "COMPLETED": return project.isCompleted; break;
              case "RUNNING": return project.isRunning; break;
              case "STOPPED": return project.isStopped; break;
              case "ERROR": return project.haveError; break;
              default: return project
            }
          }), ['dateCreated'],
            ['desc'])

      })
    }
    this.setFileData = (data) => {
      this.setState({ fileData: data })
    }
    this.setNewProjectDialog = (v) => {
      this.setState({ newProjectDialog: v })
    }

    this.cancelFileDownLoad = () => {
      this.state.abortController.abort()
    }

    this.clearDownloadData = () => {
      this.setState({ fileDownloadData: null })
    }

    this.setIsProduction = (value) => {
      this.setState({isProduction:value})
    }

    this.state = {
      isProduction:false,
      loggedInUser: null,
      connectedState: 'Disconnected',
      loginStatus: null,
      hub: null,
      connection: new HubConnectionBuilder(),
      setConnectedState: this.setConnectedState,
      closeConnection: this.closeConnection,
      rebuildHub: this.rebuildHub,
      startDate: null,
      endDate: null,
      configSettings: {},
      configFilters: [],
      projects: [],
      openSearchPalette: false,
      newProjectDialog: false,
      openProjectModal: false,
      selectedProject: null,
      projectFetchingInProgress: false,
      showStartPrompt: false,
      showReStartPrompt: false,
      projectStatusFilter: null,
      permissions: {},
      fileDownloadData: {},
      fileData: null,
      abortController: new AbortController(),
      clearDownloadData: this.clearDownloadData,
      setFileData: this.setFileData,
      getPermissions: this.getPermissions,
      setNewProjectDialog: this.setNewProjectDialog,
      setLoggedInUser: this.setLoggedInUser,
      setShowStartPrompt: this.setShowStartPrompt,
      setShowReStartPrompt: this.setShowReStartPrompt,
      setOpenSearchPalette: this.setOpenSearchPalette,
      setOpenProjectModal: this.setOpenProjectModal,
      setDateFilter: this.setDateFilter,
      getProjects: this.getProjects,
      createProject: this.createProject,
      deleteProject: this.deleteProject,
      downloadProject: this.downloadProject,
      cancelFileDownLoad: this.cancelFileDownLoad,
      getConfig: this.getConfig,
      getFilters: this.getFilters,
      updateFilters: this.updateFilters,
      updateConfig: this.updateConfig,
      getProjectFilters: this.getProjectFilters,
      getProjectConfig: this.getProjectConfig,
      tikTokRun: this.tikTokRun,
      tikTokReRun: this.tikTokReRun,
      tikTokStop: this.tikTokStop,
      editProject: this.editProject,
      setProjectStatusFilter: this.setProjectStatusFilter,
      setFilteredProjects: this.setFilteredProjects
    };
  }
  componentDidMount() {

    this.rebuildHub()


    /*  setInterval(() => {
         this.setConnectedState()
     }, 1000) */

    setTimeout(() => {
      // this.rebuildHub()
    }, 1000)

    /*  setInterval(() => {
       this.rebuildHub()
     }, 1000) */


    this.setDateFilter(moment.tz().format("MMMM YYYY"))
  }

  render() {
    return (
      <>
        <ContextServiceApi.Provider value={this.state}>
          {this.props.children}
        </ContextServiceApi.Provider>
      </>
    );
  }
}

import axios from 'axios';
import { appusers_path, api_path } from './config'
import { apiData } from '../api';
import * as authHandler from '../handlers/authHandler'

export async function TikTokRun(data, user) {
    return new Promise((resolve, reject) => {
       // resolve()
       // return
        axios({
            url:  api_path + 'TikTok/Run',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            data: data,
            params:{projectName:data?.name}

        }).then((res) => {
            resolve (res.data)
        }).catch((err) => { resolve(err) })

    });
}

export async function TikTokStop(data, user) {

    return new Promise((resolve, reject) => {
        //resolve()
      //  return
        axios({
            url:  api_path + 'TikTok/Stop',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            data: data,
            params:{projectName:data?.name}

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}

export async function TikTokReRun(data, user) {

    return new Promise((resolve, reject) => {
        //resolve()
       // return
        axios({
            url:  api_path + 'TikTok/ReRun',
            headers: {
                authorization: 'Bearer ' + user?.signInUserSession?.idToken?.jwtToken
            },
            data: data,
            params:{projectName:data?.name}

        }).then((res) => {
            resolve(res.data)
        }).catch((err) => { resolve(err) })

    });
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import { HomePage, LoginPage } from './pages'
import ProviderServiceApi from './context/ProviderServiceApi';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Authenticator.Provider>
    <ProviderServiceApi>
      <ThemeProvider>
        <Router>
          <Routes>
            {/* <Route exact path="/" element={<LoginPage />} /> */}
            <Route exact path="/" element={<HomePage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ProviderServiceApi>
  </Authenticator.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

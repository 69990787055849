/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useContext, useEffect, useState } from 'react'
import { Listbox, Popover, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { Bars3Icon, BellIcon, Cog6ToothIcon, CogIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { NewProject } from './NewProject'
import MonthFilterPicker from './MonthFilterPicker'
import SearchPalette from './SearchPalette'
import SettingsDialogWindow from './SettingsDialogWindow'
import { ContextServiceApi } from '../context/ContextServiceApi'

const user = {
  name: 'Chelsea Hagon',
  email: 'chelsea.hagon@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Calendar', href: '#', current: false },
  { name: 'Teams', href: '#', current: false },
  { name: 'Directory', href: '#', current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function FilterStatus() {
  const STATUSES = [
    { type: 'ALL' },
    { type: 'RUNNING' },
    { type: 'STOPPED' },
    { type: 'COMPLETED' },
    { type: 'ERROR' },
  ]
  const [selected, setSelected] = useState(STATUSES[0])

  const { openSearchPalette, setOpenSearchPalette, projects, setProjectStatusFilter, setOpenProjectModal } = useContext(ContextServiceApi)

  useEffect(() => {
    setProjectStatusFilter(selected)
  }, [selected])

  return (
    <div className="w-full h-full">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-0 h-full">
          <Listbox.Button className="relative w-full h-full cursor-default rounded bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected.type}</span>
            <span className="pointer-events-none absolute z-40  inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {STATUSES.map((STAT, STAT_Idx) => (
                <Listbox.Option
                  key={STAT_Idx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={STAT}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                          }`}
                      >
                        {STAT.type}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default function MainNav() {
  const { openSearchPalette, setOpenSearchPalette } = useContext(ContextServiceApi)
  return (
    <>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? ' inset-0 overflow-y-auto' : '',
            ' relative z-10  bg-sky-500/0 shadow-none lg:static lg:overflow-y-visible'
          )
        }
      >
        {({ open }) => (
          <>
            <SearchPalette />
            <div className="px-4 sm:px-6 lg:px-8 bg-black/10 backdrop-blur-2xl">
              <div className="relative flex justify-between lg:gap-8 xl:grid xl:grid-cols-12">
                <div className="flex col-span-3">
                  <div className="flex flex-shrink-0 items-center">
                    <MonthFilterPicker />
                  </div>
                </div>
                <div className="col-span-6 grid grid-cols-2 gap-x-2 ">
                  <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0"> <FilterStatus /> </div>
                  <div className="flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                    <div className="w-full">
                      <label htmlFor="search" className="sr-only">
                        Search Projects
                      </label>
                      <div className="relative"
                        onClick={() => setOpenSearchPalette(true)}>
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ">
                          <MagnifyingGlassIcon className="sm:h-5 sm:w-5 xs:h-8 xs:w-8 sm:text-gray-400 xs:text-white xs:mx-5 xs:bg-black/20 xs:rounded-lg sm:mx-0 sm:bg-white sm:rounded-none" aria-hidden="true" />
                        </div>

                        <input
                          id="search"
                          name="search"
                          className={`${openSearchPalette && 'invisible'} xs:invisible sm:visible block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6`}
                          placeholder="Search Projects"
                          type="search"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 col-span-3">
                  <div className='grid place-items-center'>
                    <button
                      type="button"
                      className="relative invisible ml-5 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-10 w-10" aria-hidden="true" />
                    </button>

                  </div>
                  <div className='grid place-items-center'> 
                  <SettingsDialogWindow/>
                    
                  </div>
                </div>
              </div>
            </div>

            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="mx-auto max-w-3xl space-y-1 px-2 pb-3 pt-2 sm:px-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={classNames(
                      item.current ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                      'block rounded-md py-2 px-3 text-base font-medium'
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{user.name}</div>
                    <div className="text-sm font-medium text-gray-500">{user.email}</div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
                  {userNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  )
}

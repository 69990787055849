export function setHubConnectionId(connectionId){
    console.log(connectionId)
    localStorage.setItem('connectionId',connectionId)
}
export function setAccessToken(token){
    console.log(token)
    localStorage.setItem('token',token)
}
export function setsUser(user){
    console.log(user)
    localStorage.setItem('user',user)
}
export function setAccessGroup(group){
    console.log(group)
    localStorage.setItem('group',group)
}
export function getHubConnectionId(){
    return localStorage.getItem('connectionId')
}

export function getAccessToken(){
    return localStorage.getItem('token')
}
export function getAccessUser(){
    return localStorage.getItem('user')
}
export function getAccessGroup(){
    return localStorage.getItem('group')
}

export function removeAccessToken(){
    localStorage.removeItem('token')
}
export function removeAccessUser(){
    localStorage.removeItem('user')
}
export function removeAccessGroup(){
    localStorage.removeItem('group')
}

export const handleAuthentication = (res) =>{
    console.log(res)

    if(res?.data?.success){
        const user = res?.data?.user
        const usergroup = res?.data?.usergroup
        const accessToken = res?.data?.token
        
        setAccessToken(accessToken)
        setAccessGroup(usergroup)
        setsUser(user)

    }
}